.morrisons-tabs {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999; }

.navbar {
  box-shadow: 0 4px 2px -1px #ccc;
  height: 75px;
  margin-bottom: 0 !important;
  background-color: #fff !important;
  border: none !important;
  font-family: "InterFace", sans-serif;
  border-radius: 0 !important; }

.navbar .logo {
  max-width: 155px;
  width: 100%;
  height: 97px;
  font-size: 0;
  background-size: cover;
  margin-top: -2px;
  margin-bottom: 0; }

#login-control {
  padding-right: 10px; }

a {
  color: #707070; }

@media screen and (max-width: 991px) {
  .navbar {
    height: 70px;
    position: fixed;
    width: 100%;
    z-index: 99; }
  .navbar .logo {
    max-width: 130px;
    height: 80px; }
  .navbar .navigation {
    background-color: #ccc;
    min-height: 0;
    margin-top: -8px; }
  .navbar .navbar-collapse.in {
    height: 100vh; }
  .navbar .navbar-toggle {
    border: none; }
  .navbar .navbar-toggle .icon-bar {
    background-color: #b8d288 !important; } }

@media screen and (min-width: 768px) {
  .navbar-collapse.collapse {
    display: none !important; }
  .collapse.in {
    display: block !important; }
  .navbar-toggle {
    display: block !important; }
  .navbar-nav > li {
    float: none; }
  .navbar {
    border-radius: 0; }
  .navbar-nav > li > a {
    padding: 8px 0 !important; } }

@media screen and (min-width: 992px) {
  .navbar {
    font-size: 16px; }
  .navbar-collapse.collapse {
    display: block !important;
    padding: 0; }
  .navbar-toggle {
    display: none !important; }
  .logo-container {
    max-height: 80px; }
  .logo-container a {
    display: block;
    height: 80px;
    width: 152px; }
  .nav-container {
    display: flex;
    align-items: center; }
  .navbar-nav > li {
    float: left; }
  #nav-categories {
    width: 100%;
    float: none;
    text-align: center;
    padding: 0 30px; }
  .navbar-nav > li {
    border: 1px solid #b8d288;
    margin-right: 8px;
    border-radius: 5px;
    background-color: #fff;
    width: 125px;
    text-align: center;
    float: none;
    display: inline-block; }
  .navbar-nav > li a {
    color: #333 !important; }
  .navbar-nav > li:hover {
    background-color: #b8d288;
    transition: 0.5s; }
  .navbar-nav > li.inverse {
    background-color: #b8d288; }
  .navbar-nav > li:last-of-type {
    margin-right: 0; } }

.nav-panel-wrapper {
  position: relative;
  height: 80px;
  padding: 0;
  margin-bottom: 0 !important;
  background-color: #fff !important;
  border: none;
  font-family: "InterFace", sans-serif;
  border-radius: 0 !important;
  z-index: 1; }
  .nav-panel-wrapper.hide-box-shadow {
    box-shadow: none; }
  .nav-panel-wrapper.fixed {
    position: fixed !important;
    width: 100%;
    z-index: 4;
    height: 75px; }
  .nav-panel-wrapper .nav-desktop-view {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    position: relative;
    background-color: #fff;
    z-index: 0;
    border-bottom: 2px solid rgba(38, 38, 38, 0.2); }
    .nav-panel-wrapper .nav-desktop-view .container {
      display: flex;
      position: relative; }
      @media screen and (max-width: 992px) {
        .nav-panel-wrapper .nav-desktop-view .container {
          width: 100%;
          justify-content: center; } }
    .nav-panel-wrapper .nav-desktop-view .logo-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      text-decoration: none !important;
      margin: auto;
      top: 0;
      bottom: 0;
      z-index: 1; }
      .nav-panel-wrapper .nav-desktop-view .logo-wrapper .logo {
        height: 40px;
        width: 100px;
        margin: auto 0;
        background-image: url("../../../assets/images/logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: relative; }
      .nav-panel-wrapper .nav-desktop-view .logo-wrapper .logo-text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0077be;
        font-size: 24px;
        padding: 0 8px;
        border: 1px solid #0077be;
        border-radius: 3px; }
    .nav-panel-wrapper .nav-desktop-view .site-type-banner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;
      color: indianred;
      -ms-flex-item-align: center;
      align-self: center;
      font-size: 16px; }
    .nav-panel-wrapper .nav-desktop-view .left-menu {
      margin: auto; }
      .nav-panel-wrapper .nav-desktop-view .left-menu .nav {
        display: flex;
        align-items: center; }
        .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu {
          width: auto; }
          .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-lead-in {
            font-size: 14px;
            margin-right: 8px;
            background-color: #00683a;
            border-radius: 5px;
            border: 1px solid #00683a;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            height: 36px;
            padding: 7px 20px;
            min-width: initial; }
            .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-lead-in .menu-icon {
              display: flex;
              justify-content: center; }
              .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-lead-in .menu-icon i {
                font-size: 10px; }
            .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-lead-in .loading-spinner {
              width: 24px;
              height: 24px;
              left: 14px; }
          .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-icon {
            display: flex;
            justify-content: center; }
            .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-icon i {
              font-size: 10px; }
          .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-text {
            margin-left: 0;
            margin-right: 10px; }
          .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options {
            border-radius: 6px; }
            .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .scrollbar > div {
              display: flex; }
            .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .menu-item {
              height: 40.5px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
              .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .menu-item a {
                width: 100%;
                height: auto;
                color: #3f4241; }
              .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .menu-item:hover {
                background-color: #faf9f9; }
              .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .menu-item:last-child {
                border-bottom: none; }
              .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .menu-item.selected {
                background-color: transparent; }
                .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu .menu-options .menu-level .menu-item.selected:hover {
                  background-color: #faf9f9; }
        .nav-panel-wrapper .nav-desktop-view .left-menu .nav .menu-open .menu-lead-in {
          background-color: #0054b1;
          border: 1px solid #0054b1;
          color: #fff; }
        @media screen and (max-width: 1199px) {
          .nav-panel-wrapper .nav-desktop-view .left-menu .nav .nav-button {
            margin-right: 0;
            padding: 7px 15px; } }
    .nav-panel-wrapper .nav-desktop-view .right-menu {
      position: absolute;
      right: 20px;
      margin: auto;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center; }
      .nav-panel-wrapper .nav-desktop-view .right-menu ul {
        margin-bottom: 0; }
      .nav-panel-wrapper .nav-desktop-view .right-menu .desktop-menu .nav-button.parent-button {
        display: inline-block; }
      .nav-panel-wrapper .nav-desktop-view .right-menu .desktop-menu .nav-button.login {
        background-color: #fff;
        border: 1px solid #0077be;
        color: #0077be; }
        .nav-panel-wrapper .nav-desktop-view .right-menu .desktop-menu .nav-button.login:hover {
          border: 1px solid #0077be;
          color: #fff; }
      .nav-panel-wrapper .nav-desktop-view .right-menu .show-basket-btn {
        display: flex;
        align-items: center;
        color: #4a4a4a;
        background: transparent; }
        @media screen and (max-width: 992px) {
          .nav-panel-wrapper .nav-desktop-view .right-menu .show-basket-btn {
            display: none; } }
        .nav-panel-wrapper .nav-desktop-view .right-menu .show-basket-btn svg {
          font-size: 26px;
          padding-right: 5px; }
      .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav {
        display: none;
        color: rgba(38, 38, 38, 0.8);
        text-align: center;
        font-size: 16px;
        cursor: pointer; }
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav .nav-burger-icon .bar1,
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav .nav-burger-icon .bar2,
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav .nav-burger-icon .bar3 {
          width: 16.5px;
          height: 2px;
          background-color: rgba(38, 38, 38, 0.8);
          margin: 4px auto;
          transition: 0.4s; }
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav.change .nav-burger-icon .bar1 {
          -webkit-transform: rotate(-45deg) translate(-4px, 5px);
          transform: rotate(-45deg) translate(-3px, 5px); }
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav.change .nav-burger-icon .bar2 {
          opacity: 0; }
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav.change .nav-burger-icon .bar3 {
          -webkit-transform: rotate(45deg) translate(-4px, -6px);
          transform: rotate(45deg) translate(-3px, -6px); }
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav .text {
          margin-top: -3px;
          color: #333; }
  @media screen and (max-width: 992px) {
    .nav-panel-wrapper {
      position: fixed !important;
      width: 100%;
      z-index: 4;
      padding: 0; }
      .nav-panel-wrapper .nav-desktop-view .left-menu {
        display: none; }
      .nav-panel-wrapper .nav-desktop-view .right-menu {
        height: auto;
        margin: 15px 0 0;
        left: 25px;
        top: -15px; }
        .nav-panel-wrapper .nav-desktop-view .right-menu .desktop-menu {
          display: none; }
        .nav-panel-wrapper .nav-desktop-view .right-menu .mobile-nav {
          display: inline-block; } }
  @media (max-width: 992px) {
    .nav-panel-wrapper .account-menu,
    .nav-panel-wrapper .show-basket-btn {
      display: none; } }
  .nav-panel-wrapper .nested-menu {
    position: relative;
    display: inline-block; }
    .nav-panel-wrapper .nested-menu .sub-menu {
      position: absolute;
      width: 220px;
      list-style: none;
      margin: 5px 9px 0 0;
      border-radius: 6px;
      padding: 0;
      background-color: #fff;
      text-align: left;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2); }
      .nav-panel-wrapper .nested-menu .sub-menu.inverse {
        right: 0; }
      .nav-panel-wrapper .nested-menu .sub-menu li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
        .nav-panel-wrapper .nested-menu .sub-menu li a,
        .nav-panel-wrapper .nested-menu .sub-menu li .logout {
          padding: 12px 16px;
          color: inherit;
          width: 100%;
          font-size: 14px;
          display: block;
          cursor: pointer;
          border: none;
          text-decoration: none; }
          .nav-panel-wrapper .nested-menu .sub-menu li a:hover,
          .nav-panel-wrapper .nested-menu .sub-menu li .logout:hover {
            background-color: #faf9f9;
            border: none;
            border-radius: 6px; }

.nav-button.parent-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 7px 25px;
  margin-right: 15px;
  text-decoration: none;
  cursor: pointer; }
  .nav-button.parent-button:hover, .nav-button.parent-button.is-active {
    color: #0054b1;
    transition: 0.5s; }
  .nav-button.parent-button:last-child {
    margin-right: 0; }
  .nav-button.parent-button .icon {
    font-size: 10px;
    margin-left: 10px; }
