.shopping-cart-page-wrapper {
  margin-top: 75px; }

.shopping-cart-page {
  position: relative;
  padding: 40px 190px 80px; }
  .shopping-cart-page .shopping-cart-loading-overlay {
    position: absolute;
    width: 100%;
    height: 10000px;
    top: 0;
    left: 0;
    background-color: #fcfcfc;
    opacity: 0.5;
    z-index: 10; }
  .shopping-cart-page .sub-title {
    margin: 0 0 30px;
    color: #0054b1;
    font-size: 28px;
    font-weight: bold;
    text-align: left; }
    .shopping-cart-page .sub-title.billing-address {
      margin: 30px 0 0;
      color: #0054b1;
      font-size: 28px;
      font-weight: bold; }
  .shopping-cart-page .registered-address {
    padding: 5px 0;
    font-size: 16px; }
  .shopping-cart-page .radio-wrapper input {
    margin-top: 2px; }
  .shopping-cart-page .cart-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .shopping-cart-page .cart-content .left-side,
    .shopping-cart-page .cart-content .right-side {
      flex-grow: 1; }
    .shopping-cart-page .cart-content .left-side {
      background-color: #fff;
      padding: 40px 20px 20px;
      margin-right: 60px;
      min-width: 60%; }
    .shopping-cart-page .cart-content .right-side {
      padding: 40px 10px 20px; }
      .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px; }
        .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header .sub-title {
          flex-grow: 1;
          margin-bottom: 0;
          color: #0054b1;
          font-size: 28px;
          font-weight: bold; }
        .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header .portion-size {
          border-radius: 4px; }
          .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header .portion-size button {
            width: 230px; }
    .shopping-cart-page .cart-content .checkout-button:hover {
      border: 1px solid #0077be;
      background-color: #fff;
      color: #0077be; }
  .shopping-cart-page .mobile-basket-summary {
    display: none;
    position: fixed;
    padding: 15px;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 1px 20px 0 #bbb;
    z-index: 2; }
    .shopping-cart-page .mobile-basket-summary .mobile-summary-toggle {
      text-align: left;
      width: 100%;
      min-height: 53px;
      position: relative;
      color: #0054b1;
      background-color: #fff; }
      .shopping-cart-page .mobile-basket-summary .mobile-summary-toggle:focus {
        outline: none; }
      .shopping-cart-page .mobile-basket-summary .mobile-summary-toggle .box-summary {
        font-size: 16px;
        font-weight: bold; }
      .shopping-cart-page .mobile-basket-summary .mobile-summary-toggle .delivery-date {
        font-size: 12px;
        color: #0054b1;
        font-weight: bold; }
      .shopping-cart-page .mobile-basket-summary .mobile-summary-toggle .chevron-arrow-up-icon {
        position: absolute;
        top: 17px;
        right: 12px;
        color: inherit; }
      .shopping-cart-page .mobile-basket-summary .mobile-summary-toggle .chevron-arrow-down-icon {
        position: absolute;
        top: 20px;
        right: 12px;
        color: inherit; }
  @media screen and (max-width: 1300px) {
    .shopping-cart-page {
      padding: 40px 100px 80px; }
      .shopping-cart-page .sub-title,
      .shopping-cart-page .sub-title.billing-address,
      .shopping-cart-page .basket-summary > .checkout-header > .sub-title {
        font-size: 20px !important; } }
  @media screen and (max-width: 991px) {
    .shopping-cart-page {
      padding: 40px 15px; }
      .shopping-cart-page .cart-header {
        width: 80%; }
      .shopping-cart-page .cart-content .left-side {
        margin-right: 20px; }
      .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header {
        display: block; }
        .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header .sub-title {
          margin-bottom: 30px; }
        .shopping-cart-page .cart-content .right-side .basket-summary .checkout-header .portion-size {
          width: 230px;
          margin: auto; } }
  @media screen and (max-width: 768px) {
    .shopping-cart-page .cart-header {
      display: none; }
    .shopping-cart-page .cart-content {
      display: block;
      padding-bottom: 42px; }
      .shopping-cart-page .cart-content .left-side,
      .shopping-cart-page .cart-content .right-side {
        width: 100%;
        margin-right: 0;
        border-radius: 5px; }
      .shopping-cart-page .cart-content .right-side {
        position: fixed;
        background-color: #f1f1f1;
        width: 100%;
        z-index: 2;
        top: 150%;
        left: 0;
        padding: 40px 20px 100px;
        height: 100%;
        right: 0;
        overflow-y: auto;
        padding-top: 110px;
        transition: top 0.5s ease; }
        .shopping-cart-page .cart-content .right-side.show {
          top: 0; }
    .shopping-cart-page .mobile-basket-summary {
      display: block; } }
  .shopping-cart-page .cart-header {
    width: 60%;
    background-color: #fff;
    border-radius: 5px;
    height: 70px;
    padding: 30px; }
    .shopping-cart-page .cart-header ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center; }
      .shopping-cart-page .cart-header ul li {
        width: 33%;
        display: flex;
        align-items: center;
        text-align: center; }
        .shopping-cart-page .cart-header ul li.delivery-step {
          cursor: pointer; }
          .shopping-cart-page .cart-header ul li.delivery-step span:hover {
            text-decoration: underline; }
        .shopping-cart-page .cart-header ul li span,
        .shopping-cart-page .cart-header ul li .fa {
          flex-grow: 1;
          font-size: 16px;
          color: #ccc; }
        .shopping-cart-page .cart-header ul li .fa {
          font-size: 10px; }
        .shopping-cart-page .cart-header ul li.active span,
        .shopping-cart-page .cart-header ul li.active .fa {
          color: #0077be; }
        .shopping-cart-page .cart-header ul li .checked-icon {
          width: 13px;
          height: 13px;
          background-image: url("./checked-icon.svg"); }

.selected-click-and-collect-address {
  margin-bottom: 20px; }
  .selected-click-and-collect-address .pick-up-location {
    margin: 10px 0 5px; }
  .selected-click-and-collect-address .delivery-point-and-time.hide {
    display: none; }

.payment-page-iframe .free-coupon-wrapper {
  text-align: center; }
  .payment-page-iframe .free-coupon-wrapper .payment-success {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: 50%;
    margin: 40px auto 40px;
    fill: #b9d287;
    background-image: url("./checked.svg"); }
  .payment-page-iframe .free-coupon-wrapper .order-number-text {
    margin-bottom: 40px; }

.payment-page-iframe .btn {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 48px;
  font-size: 18px;
  font-family: "InterFace", sans-serif;
  color: #fff;
  padding: 5px 24px;
  background-color: #0077be;
  border: 1px solid #0077be;
  border-radius: 3px;
  line-height: 28px;
  letter-spacing: 0.02em; }
  .payment-page-iframe .btn:hover {
    border: 1px solid #0077be;
    background-color: #fff;
    color: #0077be; }

.postcode-lookup .btn {
  color: #fff;
  background-color: #0077be;
  font-size: 18px;
  font-family: "Interface", sans-serif;
  font-weight: 400; }
  .postcode-lookup .btn:hover {
    border: 1px solid #0077be;
    background-color: #fff;
    color: #0077be;
    padding: 5px 11px;
    transition: none; }
