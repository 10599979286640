.profile-image {
  display: inline;
  justify-content: space-between;
  margin-bottom: 10px; }
  .profile-image .btn {
    width: 92px;
    float: right;
    display: inline; }

.profile-image-display {
  display: flex;
  justify-content: center; }
  .profile-image-display svg {
    color: #0054b1;
    font-size: 100px;
    cursor: pointer; }
  .profile-image-display #profileImage {
    object-fit: cover;
    object-position: center;
    width: 150px;
    height: 150px;
    margin: 20px 0;
    border-radius: 50%;
    cursor: pointer; }

#profile-image-input {
  display: none; }

#profile-image-browse {
  width: 92px;
  float: right; }
