.app-dialog-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(34, 34, 34, 0.44);
  overflow-y: auto; }
  .app-dialog-wrapper .app-dialog {
    min-width: 200px;
    background-color: #fff;
    border-radius: 6px;
    margin: 140px 10px;
    box-shadow: 0 0 15px 0 #222; }
    .app-dialog-wrapper .app-dialog .dialog-title {
      padding: 15px;
      border-bottom: 1px solid #ccc;
      font-size: 18px;
      text-align: center;
      margin: 0 !important;
      width: 100% !important; }
    .app-dialog-wrapper .app-dialog .dialog-content {
      padding: 40px 20px;
      font-size: 16px; }
    .app-dialog-wrapper .app-dialog .dialog-footer {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #ccc; }
      .app-dialog-wrapper .app-dialog .dialog-footer button {
        min-width: 120px;
        border-color: #0077be !important;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 48px;
        font-size: 18px;
        font-family: "InterFace", sans-serif;
        color: #fff;
        padding: 5px 24px;
        background-color: #0077be;
        border: 1px solid #0077be;
        border-radius: 3px;
        line-height: 28px;
        letter-spacing: 0.02em; }
        .app-dialog-wrapper .app-dialog .dialog-footer button:hover {
          border: 1px solid #0077be;
          background-color: #fff !important;
          color: #2a2e39; }
      .app-dialog-wrapper .app-dialog .dialog-footer .inverse-button {
        background-color: #0077be !important;
        border: 1px solid #0077be !important;
        color: #fff;
        margin-right: 0; }

.dialog-action-target {
  text-transform: capitalize; }
