html,
body,
#root {
  height: 100%; }

html {
  font-size: 16px;
  scroll-behavior: smooth; }

.modal-open {
  overflow: hidden !important; }

.App {
  display: flex;
  flex-direction: column;
  height: 100%; }

.site-content {
  flex-grow: 1; }

.number-list li {
  list-style: decimal; }

.page-footer__plus__3w4aZ {
  padding: 0.5rem !important;
  align-items: center; }

.page-footer__plus__3w4aZ:hover {
  background-color: initial;
  border-color: rgba(114, 117, 117, var(--border-opacity));
  border-left: none;
  border-top: none;
  border-right: none; }

body {
  font-family: "InterFace", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-weight: normal;
  color: #3f4241;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media screen and (max-width: 767px) {
  .banner {
    background-position: -800px -45px;
    height: 320px;
    background-repeat: no-repeat; }
  .banner-content {
    max-width: none;
    width: 94%;
    left: 3%;
    padding: 24px 24px 24px 24px;
    bottom: -155px;
    top: auto;
    -webkit-box-shadow: 0 5px 15px 0 #ccc;
    box-shadow: 0 5px 15px 0 #ccc; } }

.promo-banner {
  width: 100%;
  /* height: 40px; */
  /* position: fixed; */
  /* top: 0; */
  background-color: #fcbc00;
  /* position: relative; */
  z-index: 100000000000;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; }

.promo-banner p {
  text-align: center;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px; }

.how-it-works,
.recipes-page,
.foodbox-detail-wrapper,
.contact-us,
.shopping-cart-page {
  margin-bottom: 120px; }

.cornerBox.scribe-wrapper {
  margin: 120px 0 120px 0; }

@media (min-width: 1300px) {
  .container {
    width: 1280px !important; } }

.g-recaptcha {
  transform: scale(0.77);
  transform-origin: 0 0; }

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: 0.4 !important; }

.btn-primary {
  padding: 8px 0;
  border-radius: 5px;
  font-family: "InterFace", sans-serif;
  font-size: 16px;
  display: block;
  color: #fff;
  background-color: #0077be;
  border: 1px solid #0077be;
  text-align: center; }

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  background-color: #0077be;
  border: 1px solid #fff;
  transition: 0.5s;
  text-decoration: none;
  outline: none !important; }

button {
  background-color: #0077be;
  padding: 17px 20px;
  color: #fff;
  border-radius: 4px;
  border: none;
  outline: none !important; }

button.inverse {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #0077be; }

:focus {
  outline: none !important; }

.eatfresh-primary.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 18px;
  font-weight: normal;
  font-family: "InterFace", sans-serif;
  color: #fff;
  padding: 12px 24px;
  background-color: #0077be;
  border: 1px solid #0077be;
  border-radius: 3px;
  line-height: 28px;
  letter-spacing: 0.02em; }

.eatfresh-secondary.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 18px;
  font-weight: normal;
  font-family: "InterFace", sans-serif;
  color: #262626;
  padding: 12px 24px;
  background-color: #fff;
  border: 1px solid #0077be;
  border-radius: 3px;
  line-height: 28px;
  letter-spacing: 0.02em; }

.eatfresh-primary.btn:hover,
.eatfresh-secondary.btn:hover {
  border: 1px solid #0077be;
  background-color: #0077be;
  color: #fff; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "InterFace", sans-serif;
  font-weight: normal !important; }

.fa {
  color: #0077be; }

.slick-prev::before,
.slick-next::before {
  font-family: "slick", sans-serif;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #0077be !important;
  -webkit-font-smoothing: antialiased;
  outline: none !important;
  display: none; }

.slick-next,
.slick-prev {
  top: 33%;
  z-index: 3;
  cursor: pointer; }

.slick-next {
  right: -45px; }

.slick-prev {
  left: -45px; }

@media screen and (max-width: 830px) {
  .slick-next {
    right: -15px; }
  .slick-prev {
    left: -15px; } }

#scrollToTop {
  position: fixed;
  right: 10px;
  bottom: 95px;
  background-color: #fff;
  border-radius: 50%;
  height: 57px;
  width: 57px;
  -webkit-box-shadow: 0 0 5px 0 #6e6e6e;
  box-shadow: 0 0 5px 0 #6e6e6e;
  font-size: 12px;
  align-items: center;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  font-family: "InterFace", sans-serif;
  z-index: 2;
  line-height: normal !important;
  user-select: none;
  display: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */ }

#scrollToTop:hover {
  transform: scale(0.95); }

#scrollToTop.show {
  visibility: visible; }

.spinner-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  left: 0;
  text-align: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1001; }

.spinner-wrapper .spinner-icon {
  font-size: 60px !important;
  color: #2c893f; }

@media screen and (max-width: 991px) {
  .App.basket-not-empty .main-footer {
    margin-bottom: 110px; } }

@media screen and (max-width: 680px) {
  #scrollToTop {
    bottom: 65px; } }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 11px;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  display: flex !important;
  justify-content: center;
  flex-grow: 1; }

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
  transition: none !important;
  background-color: none !important;
  border: 7px solid transparent; }

.react-datepicker__navigation--next:hover {
  border-left-color: #5a5a5a !important;
  background-color: transparent; }

.react-datepicker__navigation--previous:hover {
  border-right-color: #5a5a5a !important;
  background-color: transparent; }

.react-datepicker {
  font-family: "InterFace", sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: absolute;
  z-index: 4;
  width: 100%;
  top: 66%;
  margin-top: 0 !important;
  margin-left: 0 !important; }

.react-datepicker__navigation {
  background: none !important;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0 !important;
  padding: 0 !important;
  border: 7px solid transparent !important;
  z-index: 1; }

.react-datepicker__header {
  background-color: #0077be !important; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #0077be !important;
  color: inherit !important;
  font-family: "InterFace", sans-serif; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
  z-index: 0; }

textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
  z-index: 0; }

select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
  z-index: 0; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.spinner-button {
  position: relative;
  display: flex;
  align-items: center; }

.spinner-button .text {
  flex-grow: 1;
  text-align: center; }

.spinner-button .spinner {
  position: absolute;
  right: 12px;
  top: 10px; }

.spinner-button .spinner .fa {
  color: inherit; }

.spinner-button .spinner-icon {
  color: #fff !important; }

.api-loading-data {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.2); }

.api-loading-data .content {
  margin: auto;
  width: 250px;
  /* height: 150px; */
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 50px; }

.api-loading-data .content .fa {
  color: inherit;
  font-size: 20px; }

.error-text {
  color: indianred; }

.added-to-cart-text {
  width: 100%;
  text-align: center;
  margin: 30px 0 0;
  font-size: 16px;
  font-family: "InterFace", sans-serif;
  color: #0077be; }

.added-to-cart-text .icon-checked {
  color: #0077be;
  font-size: 16px; }

/* Icons */
.arrow-left-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/arrow-left-icon.svg");
  background-repeat: no-repeat; }

.plus-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/plus-icon.svg");
  background-repeat: no-repeat; }

.user-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/user-icon.svg");
  background-repeat: no-repeat; }

.phone-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/call-icon.svg");
  background-repeat: no-repeat; }

.email-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/email-icon.svg");
  background-repeat: no-repeat; }

.calendar-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/calendar-icon.svg");
  background-repeat: no-repeat; }

.contact-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/contact-icon.svg");
  background-repeat: no-repeat; }

.info-circle-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/circle-filled-icon.svg");
  background-repeat: no-repeat; }

.info-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/info-icon.svg");
  background-repeat: no-repeat; }

.clear-circle-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/clear-oval-icon.svg");
  background-repeat: no-repeat; }

.chevron-arrow-right-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/chevron-arrow.svg");
  background-repeat: no-repeat;
  margin-left: 5px; }

.chevron-arrow-left-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/chevron-arrow.svg");
  background-repeat: no-repeat;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 5px; }

.chevron-arrow-down-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/chevron-arrow.svg");
  background-repeat: no-repeat;
  transform: rotate(90deg); }

.chevron-arrow-up-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/chevron-arrow.svg");
  background-repeat: no-repeat;
  transform: rotate(270deg); }

.home-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/home-icon.svg");
  background-repeat: no-repeat; }

.card-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/card-icon.svg");
  background-repeat: no-repeat; }

.times-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/times-icon.svg");
  background-repeat: no-repeat; }

.calorie-icon {
  margin-right: 5px;
  line-height: 1; }

.calorie-icon::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("./assets/calories-icon.svg");
  background-repeat: no-repeat;
  background-size: cover; }

.clock-icon {
  margin-right: 5px;
  line-height: 1; }

.clock-icon::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("./assets/clock.svg");
  background-repeat: no-repeat;
  background-size: cover; }

.padlock-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/padlock-icon.svg");
  background-repeat: no-repeat; }

.leaf-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/vegetarian.svg");
  background-repeat: no-repeat; }

.checked-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/checked-icon.svg");
  background-repeat: no-repeat; }

.circle-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/oval-icon.svg");
  background-repeat: no-repeat; }

.visibility-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/visability-icon.svg");
  background-repeat: no-repeat; }

.no-visibility-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/novisability-icon.svg");
  background-repeat: no-repeat; }

.basket-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/basket-icon.svg");
  background-repeat: no-repeat; }

.autorenew-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("./assets/autorenew-icon.svg");
  background-repeat: no-repeat; }

/* Morrisons CSS Override */
th,
td.subHeading {
  background-color: transparent; }

td,
th {
  border: none; }

#content p,
#content li,
#content .med,
#content dd,
#content caption,
#content button {
  font-weight: normal;
  word-wrap: break-word; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  width: 100%;
  display: flex;
  color: #fff; }

.react-datepicker__current-month {
  font-size: 1.5rem;
  color: #fff; }

.react-datepicker__month-container {
  width: 100%; }

@media screen and (min-width: 400px) {
  .react-datepicker__day,
  .react-datepicker__day-name {
    padding: 2% 5% !important; } }

.react-datepicker__day-names .react-datepicker__day-name {
  color: #fff; }

.capacity-reached-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #0077be; }

.capacity-reached-banner {
  background-color: #0077be;
  color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 7px 20px;
  max-width: 80vw;
  text-align: center;
  font-family: "InterFace", sans-serif;
  font-size: 14px; }

@media screen and (max-width: 991px) {
  .capacity-reached-banner {
    padding-top: 15px; } }

.primary-button {
  border: 1px solid #0077be;
  background-color: #0077be;
  color: #fff;
  border-radius: 4px;
  font-size: 14px; }
  .primary-button:hover {
    background-color: #fff;
    color: #0077be; }
