.the-best {
  font-style: italic; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.lbl-bold,
.download-caption {
  font-size: 18px;
  font-weight: 700; }

.product-life {
  margin-top: 30px;
  font-size: 16px; }

.apologies-text {
  margin: 20px 0; }

.warning-text p span {
  display: block; }

.content-sticky .recipe-detail-wrapper {
  margin-top: 75px; }

@media screen and (max-width: 991px) {
  .subscription-recipes {
    padding-top: 0; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .content-sticky .recipe-detail-wrapper {
    margin-top: 75px; } }

.recipe-detail-wrapper {
  font-family: "InterFace", sans-serif;
  position: relative;
  min-height: 450px;
  padding-bottom: 0 !important; }
  .recipe-detail-wrapper img.roundel-banner {
    position: absolute;
    width: 100px !important;
    height: 100px !important;
    top: 20px;
    right: 60px;
    border-radius: 50%; }
  .recipe-detail-wrapper .recipe-detail-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    min-height: 581px; }
    .recipe-detail-wrapper .recipe-detail-container .container {
      display: flex; }
      .recipe-detail-wrapper .recipe-detail-container .container .left {
        width: 35%; }
        .recipe-detail-wrapper .recipe-detail-container .container .left button {
          margin-top: 25px;
          background-color: #fff;
          font-size: 16px;
          border: 1px solid #0077be;
          padding: 10px 40px;
          border-radius: 5px;
          color: #9b9b9b;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
          outline: none !important; }
          .recipe-detail-wrapper .recipe-detail-container .container .left button:hover {
            background-color: #0054b1;
            color: #fff; }
      .recipe-detail-wrapper .recipe-detail-container .container .right {
        width: 65%;
        height: fit-content;
        min-height: 660px;
        margin-top: -77px;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px 40px 40px 40px;
        display: flex;
        flex-direction: column; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .title-wrapper {
          display: flex;
          padding-top: 27px;
          justify-content: space-between; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .title-wrapper .title-left {
            width: 75%; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .title-wrapper h1 {
            font-weight: bold !important;
            font-size: 28px;
            color: #0054b1;
            margin: 0; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .title-wrapper h4 {
            color: #4a4a4a;
            font-weight: bold !important;
            font-size: 18px;
            text-align: left;
            margin-bottom: 0;
            margin-top: 0; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .title-wrapper .recipe-price {
            padding-top: 10px; }
            .recipe-detail-wrapper .recipe-detail-container .container .right .title-wrapper .recipe-price span {
              font-size: 32px;
              font-weight: bold;
              color: #0054b1; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #ddd; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-left {
            font-size: 18px;
            width: 55%;
            margin-top: 30px; }
            .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-left .info-title {
              font-weight: bold; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .add-to-basket-wrapper {
            width: 144px; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .add-to-basket-button {
            color: #fff;
            width: 144px;
            padding: 10px 15px;
            font-size: 16px;
            font-weight: bold;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            margin-bottom: 15px; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity {
            justify-content: space-between;
            width: 144px; }
            .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity #qty {
              display: none; }
            .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity button {
              width: 32px;
              height: 32px;
              background-color: #fff;
              border: 1px solid #d7dae2 !important;
              border-radius: 5px;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
              color: #a4a4a4;
              font-size: 24px; }
              .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity button.quantity-decrement {
                padding-top: 2px !important; }
              .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity button:hover {
                color: #fff; }
            .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity .quantity-increment:hover,
            .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity .quantity-decrement:hover {
              color: #0077be;
              background-color: #fff; }
            .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-details .info-right .basket-quantity input[type="number"] {
              width: 64px !important;
              margin-left: 0 !important;
              margin-right: 0 !important;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
              border: 1px solid #707070; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs ul {
          display: flex;
          justify-content: space-between;
          border-bottom: 0;
          margin-bottom: 20px;
          margin-top: 20px;
          padding-left: 0; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs ul .react-tabs__tab--selected {
            border: none;
            border-bottom: 3px solid #0054b1;
            color: #0054b1; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs ul li {
            width: 25%;
            text-align: center;
            color: #c19a5d;
            font-size: 16px;
            font-weight: bold; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs .react-tabs__tab-panel p {
          font-size: 18px;
          color: #4a4a4a; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs .react-tabs__tab-panel .info-title {
          font-weight: bold; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-info .recipe-description {
          margin-top: 10px;
          font-size: 21px;
          color: #4a4a4a; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .recipe-info .recipe-description p {
            margin: 0; }
      .recipe-detail-wrapper .recipe-detail-container .container .image-name-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: unset;
        width: 100%;
        justify-content: space-between; }
      .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper {
        position: relative;
        width: 100%; }
        .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider {
          width: 100%; }
          .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider img {
            object-fit: cover; }
          .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-next,
          .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-prev {
            background-color: #fff;
            box-shadow: 1px 1px 10px 1px #ddd;
            top: 50%; }
          .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-next {
            right: -24px;
            z-index: 0; }
          .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-prev {
            left: -26px; }
          .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-dots {
            bottom: -60px;
            padding: 10px 0; }
            .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-dots li {
              margin: 0; }
              .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-dots li button {
                visibility: hidden; }
                .recipe-detail-wrapper .recipe-detail-container .container .recipe-image-wrapper .slick-slider .slick-dots li button::before {
                  font-size: 12px;
                  visibility: visible; }
    .recipe-detail-wrapper .recipe-detail-container .back-btn {
      position: absolute;
      top: -50px;
      left: 30px;
      z-index: 1;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
      outline: none !important;
      background-color: #fff;
      font-size: 16px;
      border: 1px solid #0054b1;
      padding: 10px 20px;
      border-radius: 5px;
      color: #0054b1;
      display: flex;
      align-items: center; }
      .recipe-detail-wrapper .recipe-detail-container .back-btn:hover {
        background-color: #0054b1;
        color: #fff; }
  @media screen and (max-width: 365px) {
    .recipe-detail-wrapper .recipe-detail-container .recipe-info-wrapper .recipe-info .recipe-top-info {
      padding-bottom: 20px; }
      .recipe-detail-wrapper .recipe-detail-container .recipe-info-wrapper .recipe-info .recipe-top-info .stock-message {
        padding: 0 !important;
        display: block;
        margin-bottom: 20px; }
        .recipe-detail-wrapper .recipe-detail-container .recipe-info-wrapper .recipe-info .recipe-top-info .stock-message span {
          padding: 3px 0 !important;
          width: 100%;
          top: 0 !important;
          text-align: center; }
    .recipe-detail-wrapper .recipe-detail-container .recipe-info-wrapper .recipe-info .recipe-time {
      margin-bottom: 5px; } }
  @media screen and (max-width: 767px) {
    .recipe-detail-wrapper .recipe-detail-container {
      background-color: #fff;
      display: block;
      padding: 0; }
      .recipe-detail-wrapper .recipe-detail-container .back-btn {
        opacity: 0.85;
        left: 10px; }
      .recipe-detail-wrapper .recipe-detail-container .container {
        padding: 0; }
        .recipe-detail-wrapper .recipe-detail-container .container .slick-slider .slick-next {
          right: 15px; }
        .recipe-detail-wrapper .recipe-detail-container .container .slick-slider .slick-prev {
          left: 15px; }
        .recipe-detail-wrapper .recipe-detail-container .container .image-name-wrapper .recipe-image-wrapper .slick-slider .slick-dots {
          bottom: -52px; }
        .recipe-detail-wrapper .recipe-detail-container .container .recipe-info-wrapper .recipe-info .recipe-top-info .stock-message span {
          padding: 3px 6px 3px 25px;
          top: 20px; }
      .recipe-detail-wrapper .recipe-detail-container .recipe-image-wrapper {
        width: auto;
        padding: 0; } }
  @media screen and (max-width: 991px) {
    .recipe-detail-wrapper .recipe-detail-content {
      padding-top: 30px;
      padding-left: 15px;
      padding-right: 15px;
      flex-wrap: wrap; }
      .recipe-detail-wrapper .recipe-detail-content .recipe-content-left,
      .recipe-detail-wrapper .recipe-detail-content .recipe-content-right {
        width: 100%; }
      .recipe-detail-wrapper .recipe-detail-content .recipe-content-left .recipe-allergens ul {
        padding: 0 0 32px; }
    .recipe-detail-wrapper .recipe-detail-container .container {
      flex-direction: column;
      align-items: center; }
      .recipe-detail-wrapper .recipe-detail-container .container .right {
        width: 100% !important;
        margin-top: 0; }
        .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs ul {
          flex-wrap: wrap; }
          .recipe-detail-wrapper .recipe-detail-container .container .right .info-tabs ul li {
            min-width: 100px; }
      .recipe-detail-wrapper .recipe-detail-container .container .left {
        width: 90% !important; }
      .recipe-detail-wrapper .recipe-detail-container .container .back-btn {
        top: -50px !important; }
      .recipe-detail-wrapper .recipe-detail-container .container .image-name-wrapper .recipe-info-wrapper {
        width: 100%;
        margin: 0;
        padding: 0 0 35px; }
        .recipe-detail-wrapper .recipe-detail-container .container .image-name-wrapper .recipe-info-wrapper .recipe-info {
          margin-top: 16px;
          width: 100%; }
      .recipe-detail-wrapper .recipe-detail-container .container .image-name-wrapper .slick-slide div {
        display: flex;
        justify-content: center; }
      .recipe-detail-wrapper .recipe-detail-container .container .image-name-wrapper img {
        height: 300px;
        width: inherit !important; }
    .recipe-detail-wrapper .social-share {
      right: 43vw !important; }
      .recipe-detail-wrapper .social-share span {
        display: block;
        width: 100%;
        text-align: center; } }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-top-info .stock-message span {
      top: 20px;
      padding: 3px 6px 3px 25px; }
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-top-info .recipe-time,
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-top-info .recipe-calories {
      font-size: 13px; }
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-life {
      padding-bottom: 30px;
      font-size: 12px; }
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-description p {
      font-size: 1.8vw;
      line-height: inherit; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-top-info .stock-message span {
      padding: 3px 6px 3px 25px; }
    .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-life {
      padding-bottom: 29px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-top-info {
    padding-bottom: 15px; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-top-info {
    padding-bottom: 25px; } }

@media screen and (max-width: 480px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-title h1 {
    font-size: 24px; }
  .recipe-image-wrapper img {
    height: 200px !important; }
  .title-wrapper h1 {
    font-size: 20px !important; }
  .title-wrapper .recipe-price span {
    font-size: 24px !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-title h1 {
    font-size: 24px;
    margin: 0 0 10px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info .recipe-add-basket button {
    font-size: 14px;
    padding: 7px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info {
    height: 100%; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .recipe-detail-wrapper .recipe-info-wrapper .recipe-info {
    margin-top: 20px; } }

@media screen and (min-width: 1300px) {
  .recipe-detail-wrapper .recipe-detail-container .container {
    width: 1280px !important;
    padding: 0; } }

.download-link {
  color: #0077be !important;
  text-decoration: underline; }
  .download-link:hover {
    text-decoration: none; }

.radio-wrapper .radio input[type="radio"] {
  margin-top: 2px; }

.additional-text {
  margin-top: 30px; }

.recipe-serving-suggestion {
  margin: 0 auto;
  text-align: right; }

.product-detail-banner {
  background-image: url("../../../assets/bbsImages/Sweet chilli salmon .jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 105px;
  width: 100%;
  z-index: -1; }

.product-detail-banner::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 119, 190, 0.73); }

@media screen and (max-width: 480px) {
  .recipe-description {
    font-size: 18px !important; }
  .recipe-details .info-left span,
  .recipe-details .info-left p {
    font-size: 16px !important; }
  .react-tabs__tab-panel p {
    font-size: 16px !important; }
  .recipe-details,
  .title-wrapper {
    flex-direction: column !important; }
  .recipe-details .info-left {
    width: 100% !important; }
  .recipe-details .product-quantity {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    .recipe-details .product-quantity .weight-select {
      width: 144px; }
  .title-wrapper .title-left {
    width: 100% !important; } }
