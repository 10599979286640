.item-select-wrapper {
  position: relative;
  margin-bottom: 30px; }
  .item-select-wrapper .select-toggle {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    cursor: pointer; }
    .item-select-wrapper .select-toggle .title {
      flex-grow: 1; }
    .item-select-wrapper .select-toggle .chevron-arrow-down-icon {
      color: inherit;
      margin-left: 20px; }
  .item-select-wrapper ul {
    width: 100%;
    position: absolute;
    left: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 0;
    overflow-y: auto;
    transition: min-height 0.3s ease;
    background-color: #fff; }
    .item-select-wrapper ul.show {
      min-height: 180px;
      z-index: 3;
      box-shadow: 0 4px 5px 2px #ccc;
      border-radius: 0 0 6px 6px; }
    .item-select-wrapper ul li {
      display: flex;
      align-items: center;
      padding: 8px 0;
      margin: 0 10px;
      border-bottom: 1px solid #ccc;
      cursor: pointer; }
      .item-select-wrapper ul li:last-child {
        border-bottom: 0;
        margin-bottom: 0; }
      .item-select-wrapper ul li:hover {
        background-color: #f2f2f2; }
      .item-select-wrapper ul li .fa {
        margin-left: auto; }
      .item-select-wrapper ul li .checked-icon {
        margin-left: auto; }
    @media screen and (max-width: 992px) {
      .item-select-wrapper ul {
        bottom: 42px; }
        .item-select-wrapper ul.show {
          border: 1px solid #ccc;
          box-shadow: none;
          border-radius: 6px 6px 0 0; } }
