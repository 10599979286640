.nav-mobile-view {
  position: fixed;
  left: -260px;
  width: 240px;
  height: 100%;
  background-color: #fff;
  transition: 0.5s;
  z-index: 3;
  padding: 20px 0;
  overflow-y: auto;
  top: 58px;
  box-shadow: -1px 0 5px 0 rgba(90, 90, 90, 0.15); }
  .nav-mobile-view.show {
    left: 0; }

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 -10px;
  background-color: rgba(51, 51, 51, 0.22);
  visibility: hidden; }
  .menu-overlay.show {
    visibility: visible;
    z-index: 2; }

.mobile-login-wrapper {
  padding: 15px 15px 10px;
  border-bottom: 1px solid #ccc;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 15px; }
  .mobile-login-wrapper a {
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px;
    margin-right: 8px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #0077be;
    color: inherit;
    text-decoration: none;
    text-align: center; }
    .mobile-login-wrapper a:hover, .mobile-login-wrapper a.active {
      background-color: #0077be;
      border: 1px solid #0077be;
      transition: 0.5s; }
  .mobile-login-wrapper .auth-button {
    display: inline;
    font-family: "InterFace", sans-serif;
    text-transform: capitalize;
    font-size: 16px;
    padding: 6px 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #0077be;
    color: inherit;
    text-decoration: none;
    cursor: pointer; }
    .mobile-login-wrapper .auth-button:hover, .mobile-login-wrapper .auth-button.active {
      background-color: #0077be;
      border: 1px solid #0077be;
      transition: 0.5s; }

.mobile-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  padding-bottom: 62px; }
  .mobile-menu-list .no-link {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    color: #262626;
    text-decoration: none;
    outline: none;
    cursor: pointer; }
  .mobile-menu-list li {
    border-top: 1px solid rgba(38, 38, 38, 0.2); }
    .mobile-menu-list li a {
      display: flex;
      align-items: center;
      padding: 17px 20px;
      color: #262626;
      text-decoration: none;
      outline: none;
      cursor: pointer; }
      .mobile-menu-list li a.active, .mobile-menu-list li a:hover {
        padding-left: 25px; }
        .mobile-menu-list li a.active span, .mobile-menu-list li a:hover span {
          padding: 0 10px;
          border-left: 2px solid #0077be;
          line-height: 20px;
          font-family: "InterFace", sans-serif;
          color: #0077be; }
      .mobile-menu-list li a span {
        font-family: "InterFace", sans-serif;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em; }
    .mobile-menu-list li.mobile-nav-heading {
      background-color: rgba(0, 119, 190, 0.1);
      border-top: none; }
      .mobile-menu-list li.mobile-nav-heading span {
        font-family: "InterFace", sans-serif;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #262626;
        text-decoration: none;
        outline: none;
        cursor: pointer; }
    .mobile-menu-list li:last-child a {
      border-bottom: none; }
  .mobile-menu-list .multi-menu.concept-menu .title {
    color: rgba(34, 38, 37, 0.87); }
  .mobile-menu-list .multi-menu.concept-menu .main-category .title {
    font-size: 14px; }
  .mobile-menu-list .multi-menu .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #262626;
    font-size: 18px;
    padding: 12px 20px; }
    .mobile-menu-list .multi-menu .title .text {
      flex-grow: 1;
      border-bottom: none;
      font-size: 18px;
      font-family: "InterFace", sans-serif; }
    .mobile-menu-list .multi-menu .title .icon .bar1,
    .mobile-menu-list .multi-menu .title .icon .bar2 {
      width: 10px;
      height: 2px;
      background-color: #333333;
      margin: 6px auto;
      transition: 0.4s; }
    .mobile-menu-list .multi-menu .title .icon .bar1 {
      -webkit-transform: rotate(-90deg) translate(-4px, 0);
      transform: rotate(-90deg) translate(-4px, 0); }
    .mobile-menu-list .multi-menu .title .icon .bar2 {
      -webkit-transform: rotate(0deg) translate(0, -4px);
      transform: rotate(0deg) translate(0, -4px); }
    .mobile-menu-list .multi-menu .title .icon.change .bar1 {
      opacity: 0; }
    .mobile-menu-list .multi-menu .title.active {
      background-color: #f1f1f1; }
  .mobile-menu-list .multi-menu .sub-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 0;
    transition: max-height 0.5s ease;
    overflow: hidden;
    padding-left: 10px; }
    .mobile-menu-list .multi-menu .sub-menu.show {
      max-height: 100%;
      overflow-y: auto; }
      .mobile-menu-list .multi-menu .sub-menu.show.morrisons-more {
        max-height: 480px;
        min-height: 480px; }
    .mobile-menu-list .multi-menu .sub-menu li a {
      padding: 13px 25px;
      border-bottom: 1px solid #ccc;
      display: block;
      color: inherit;
      font-size: 14px;
      text-decoration: none;
      outline: none; }
    .mobile-menu-list .multi-menu .sub-menu li:last-child a {
      border-bottom: none; }
    .mobile-menu-list .multi-menu .sub-menu li:last-child a {
      border-bottom: none; }
  .mobile-menu-list .user-login .logout {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #262626;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(38, 38, 38, 0.2); }
    .mobile-menu-list .user-login .logout span {
      font-family: "InterFace", sans-serif; }
