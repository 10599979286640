footer {
  background-color: #303231; }
  footer .top {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 100px 0 70px 0;
    font-family: 'InterFace', sans-serif; }
    footer .top img {
      max-height: 70px; }
    footer .top .column {
      padding: 0 20px;
      margin-bottom: 30px; }
      footer .top .column h4 {
        font-size: 16px;
        margin-bottom: 25px;
        margin-top: 0;
        color: #0077be; }
      footer .top .column a {
        color: #fff;
        display: block;
        cursor: pointer;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0; }
