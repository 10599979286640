.box-summary header {
  width: 100%;
  font-size: 18px;
  margin: 0 0 15px;
  text-align: center; }

.box-summary .quantity-text {
  width: 100%;
  text-align: right;
  margin-left: -5px; }

.box-summary .basket-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(193, 154, 93, 0.5); }
  .box-summary .basket-item:last-child {
    border-bottom: none; }
  .box-summary .basket-item .basket-item-info {
    display: flex;
    align-items: flex-start;
    align-items: center; }
    .box-summary .basket-item .basket-item-info .image {
      position: relative;
      width: 100%;
      max-width: 95px;
      height: 95px;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid #ccc;
      border-radius: 6px;
      cursor: pointer; }
      .box-summary .basket-item .basket-item-info .image .updating-recipe {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.66);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .box-summary .basket-item .basket-item-info .image .updating-recipe .fa {
          color: inherit; }
    .box-summary .basket-item .basket-item-info .name-wrapper {
      flex-grow: 1;
      margin: 0 15px; }
      .box-summary .basket-item .basket-item-info .name-wrapper .name {
        font-size: 16px; }
        .box-summary .basket-item .basket-item-info .name-wrapper .name:hover {
          color: #0077be;
          text-decoration: underline;
          cursor: pointer; }
      .box-summary .basket-item .basket-item-info .name-wrapper .whats-in-box {
        margin-bottom: 10px; }
      .box-summary .basket-item .basket-item-info .name-wrapper .serving {
        font-size: 12px;
        color: #b8d288; }
      .box-summary .basket-item .basket-item-info .name-wrapper .cooking-time {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 10px 0 0; }
        .box-summary .basket-item .basket-item-info .name-wrapper .cooking-time div {
          margin-right: 25px;
          margin-bottom: 10px; }
      .box-summary .basket-item .basket-item-info .name-wrapper .quantity {
        display: flex;
        text-align: center;
        width: fit-content;
        width: intrinsic;
        width: -moz-max-content;
        width: -webkit-max-content; }
        .box-summary .basket-item .basket-item-info .name-wrapper .quantity .value {
          display: block;
          margin: 0 15px;
          line-height: 2.2; }
        .box-summary .basket-item .basket-item-info .name-wrapper .quantity .increase,
        .box-summary .basket-item .basket-item-info .name-wrapper .quantity .decrease {
          width: 30px;
          height: 30px;
          border: 1px solid #0077be;
          border-radius: 50%;
          display: flex;
          align-items: center;
          margin: 0 auto;
          background-color: #fff;
          cursor: pointer; }
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .increase .plus-icon,
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .increase .minus-icon,
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .decrease .plus-icon,
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .decrease .minus-icon {
            margin: auto;
            color: inherit;
            font-size: 12px; }
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .increase .minus-icon,
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .decrease .minus-icon {
            width: 10px;
            height: 2px;
            background-color: #929292; }
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .increase.disabled,
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .decrease.disabled {
            cursor: default;
            background-color: #e3e3e3;
            border: none;
            visibility: hidden; }
        .box-summary .basket-item .basket-item-info .name-wrapper .quantity .remove {
          font-size: 12px;
          color: indianred;
          cursor: pointer; }
          .box-summary .basket-item .basket-item-info .name-wrapper .quantity .remove:hover {
            text-decoration: underline; }
      .box-summary .basket-item .basket-item-info .name-wrapper .remove-item {
        margin-top: 10px;
        color: #0077be;
        text-decoration: underline;
        cursor: pointer; }
        .box-summary .basket-item .basket-item-info .name-wrapper .remove-item:hover {
          text-decoration: none; }
    .box-summary .basket-item .basket-item-info .price {
      font-size: 16px;
      color: #0054b1;
      font-weight: bold; }
  .box-summary .basket-item .message-preview {
    margin: 10px 0; }
    .box-summary .basket-item .message-preview .your-message {
      font-size: 16px; }
    .box-summary .basket-item .message-preview p {
      font-size: 16px; }

.box-summary .empty-basket {
  width: 100%;
  font-size: 18px;
  color: #adadad;
  padding: 20px;
  text-align: center; }

.box-summary .box-total {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(193, 154, 93, 0.5);
  margin-bottom: 15px;
  font-size: 16px; }
  .box-summary .box-total:last-child {
    border-bottom: none; }
  .box-summary .box-total .title {
    flex-grow: 1; }
