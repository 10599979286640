.details-wrapper {
  justify-content: space-between; }

.details-wrapper .personal-details,
.details-wrapper .address-details,
.details-wrapper .payment-details {
  background-color: #fff;
  align-self: flex-start;
  border-radius: 5px; }

.details-wrapper .personal-details h4,
.details-wrapper .address-details h4,
.details-wrapper .payment-details h4 {
  color: #0077be;
  font-size: 19px;
  margin: 0;
  padding: 0; }

.details-wrapper .personal-details .content {
  z-index: 1; }

.details-wrapper .personal-details .content .item {
  display: grid;
  grid-template-columns: 25px auto 100px;
  align-items: center;
  margin-bottom: 10px; }

.details-wrapper .personal-details .content .item .icon-wrapper {
  color: #0077be; }

.details-wrapper .personal-details .content .inline-edit-panel {
  margin-bottom: 20px; }

.details-wrapper .personal-details .content .inline-edit-panel .btn {
  margin-top: 10px;
  background-color: #0077be; }

.cancel-link {
  color: #0077be;
  text-decoration: underline;
  cursor: pointer; }

.cancel-link:hover {
  text-decoration: none; }

.inverse-button {
  border: 1px solid #0077be;
  margin-right: 8px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  float: none;
  display: inline-block;
  padding: 6px 12px; }

.inverse-button:hover {
  background-color: #0077be;
  color: #fff; }

.account-addresses {
  border-bottom: 1px solid rgba(193, 154, 93, 0.5); }

.account-addresses .address-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .account-addresses .address-wrapper .icon .home-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px; }

.account-addresses .button-wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 20px; }

.account-addresses .button-wrapper .btn-block {
  margin: 0; }

.address-list {
  overflow-y: auto; }

.add-new-address {
  position: relative;
  margin: 20px 0;
  border: 1px solid #0077be;
  border-radius: 4px; }

.add-new-address .toggle-add-address {
  width: 100%;
  padding: 10px;
  text-align: left;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center; }

.add-new-address .toggle-add-address span:first-child {
  flex-grow: 1; }

.add-new-address .toggle-add-address .cancel {
  color: #929292; }

.add-new-address .toggle-add-address .cancel .fa {
  font-size: 14px;
  color: #929292; }

.add-new-address .toggle-add-address .fa {
  margin-right: 5px;
  font-size: 12px; }

.add-new-address .toggle-add-address .times-icon {
  background-image: url("./addressDetails//times-icon.svg"); }

.add-new-address .add-address-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  border-top: none;
  top: 0;
  z-index: 3; }

.add-new-address .add-address-content .manual-address-toggle {
  color: #0077be;
  margin-bottom: 15px;
  margin-top: -15px;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted; }

.add-new-address .add-address-content .save-address button {
  height: 45px; }
  .add-new-address .add-address-content .save-address button .text {
    color: #fff; }
  .add-new-address .add-address-content .save-address button:hover .text {
    color: #0077be; }

.save-address {
  z-index: 1;
  position: relative; }

.add-new-address .add-address-content .form-group select {
  height: 45px;
  padding-top: 20px; }

@media screen and (max-width: 992px) {
  .details-wrapper {
    display: block; }
  .details-wrapper .personal-details,
  .details-wrapper .address-details,
  .details-wrapper .payment-details {
    width: 100%;
    margin-bottom: 20px; }
  .details-wrapper .personal-details .content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    padding: 0 15px 0 5px; }
  .add-new-address .add-address-content {
    position: relative;
    top: 0; }
  .togglePanel {
    cursor: pointer; } }

.icon-account,
.icon-home,
.icon-phone,
.icon-mail,
.icon-card {
  margin-right: 10px;
  color: #0077be; }

.account-details-field {
  margin-bottom: 10px; }
  .account-details-field .non-edit-mode {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: nowrap;
    justify-content: space-between; }
    .account-details-field .non-edit-mode .user-icon,
    .account-details-field .non-edit-mode .phone-icon,
    .account-details-field .non-edit-mode .card-icon {
      margin-right: 10px;
      width: 15px;
      height: 15px; }
    .account-details-field .non-edit-mode .text,
    .account-details-field .non-edit-mode .card-expiry {
      flex-grow: 1; }
      @media screen and (max-width: 480px) {
        .account-details-field .non-edit-mode .text,
        .account-details-field .non-edit-mode .card-expiry {
          width: 100%;
          margin-bottom: 10px; } }
      @media screen and (min-width: 481px) and (max-width: 1199px) {
        .account-details-field .non-edit-mode .text,
        .account-details-field .non-edit-mode .card-expiry {
          flex-grow: initial;
          width: 50%;
          margin: 10px 0; } }
    .account-details-field .non-edit-mode .edit-btn {
      width: 92px;
      margin-left: 20px; }
  .account-details-field .edit-mode .floating-input {
    width: 100%; }
  .account-details-field .edit-mode .double-input-fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0; }
    .account-details-field .edit-mode .double-input-fields .left,
    .account-details-field .edit-mode .double-input-fields .right {
      width: 48%; }
  .account-details-field .edit-mode .submit-btn {
    margin-top: 10px; }
    .account-details-field .edit-mode .submit-btn .spinner {
      top: 7px; }

.edit-payment-btn {
  position: relative;
  margin-right: 0;
  min-width: 100px; }
  .edit-payment-btn .spinner {
    position: absolute;
    top: 5px;
    right: 5px; }

.edit-address-wrapper {
  position: relative;
  margin-bottom: 15px; }
  .edit-address-wrapper .edit-address {
    position: relative;
    width: 100%;
    background-color: #fff;
    z-index: 3;
    margin-top: 15px; }
  .edit-address-wrapper .bg-error {
    background-color: indianred;
    border-color: indianred;
    color: #fff; }
