.bbs-forgot-password {
  display: flex;
  background: url("../../assets/images/Bg-noblue.png") no-repeat 0 0;
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 100vh; }
  .bbs-forgot-password .forgot-password-container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 6% 6%;
    min-height: 1250px; }
    .bbs-forgot-password .forgot-password-container .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      margin-top: 70px; }
      .bbs-forgot-password .forgot-password-container .left .text h2 {
        font-family: "InterFace", sans-serif;
        font-size: 4.75vw;
        color: #fff;
        line-height: 1;
        margin: 0 0 20px; }
      .bbs-forgot-password .forgot-password-container .left .text h4 {
        font-family: "InterFace", sans-serif;
        color: #fff;
        font-size: 24px;
        max-width: 435px;
        margin: 0; }
      .bbs-forgot-password .forgot-password-container .left .orderly-info {
        color: #fff;
        font-size: 18px; }
        .bbs-forgot-password .forgot-password-container .left .orderly-info a {
          color: #fff;
          font-weight: 600; }
    .bbs-forgot-password .forgot-password-container .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 48%; }
      .bbs-forgot-password .forgot-password-container .right .forgot-password-panel {
        width: 100%;
        max-width: 620px;
        background-color: #fff;
        padding: 10% 8% 7%;
        align-self: center; }
        .bbs-forgot-password .forgot-password-container .right .forgot-password-panel h3 {
          font-size: 45px;
          margin: 0 0 15px; }
        .bbs-forgot-password .forgot-password-container .right .forgot-password-panel p {
          margin: 0 0 20px; }
        .bbs-forgot-password .forgot-password-container .right .forgot-password-panel .forgot-password {
          display: flex;
          justify-content: space-between; }
        .bbs-forgot-password .forgot-password-container .right .forgot-password-panel .button-wrapper {
          margin: 20px 0; }
        .bbs-forgot-password .forgot-password-container .right .forgot-password-panel .not-registered {
          text-align: center; }
          .bbs-forgot-password .forgot-password-container .right .forgot-password-panel .not-registered p {
            margin: 0; }
      .bbs-forgot-password .forgot-password-container .right .orderly-logo {
        align-self: flex-end; }
  @media screen and (max-width: 991px) {
    .bbs-forgot-password .forgot-password-container .left,
    .bbs-forgot-password .forgot-password-container .right {
      width: 100%; }
    .bbs-forgot-password .forgot-password-container .left {
      align-items: center; }
      .bbs-forgot-password .forgot-password-container .left img {
        max-width: 200px; }
      .bbs-forgot-password .forgot-password-container .left .text {
        margin: 20px 0 10px;
        text-align: center; }
        .bbs-forgot-password .forgot-password-container .left .text h2 {
          font-size: 38px; }
        .bbs-forgot-password .forgot-password-container .left .text h4 {
          max-width: none; }
      .bbs-forgot-password .forgot-password-container .left .orderly-info {
        display: none; }
    .bbs-forgot-password .forgot-password-container .right {
      justify-content: center;
      padding-top: 20px; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .bbs-forgot-password .forgot-password-container .left .text h2 {
      font-size: 62px;
      line-height: 62px; }
    .bbs-forgot-password .forgot-password-container .right .forgot-password-panel {
      padding: 50px 40px 30px; } }
