.recipe {
  position: relative;
  width: 23.4%;
  margin-bottom: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  background-color: #f6f6f6;
  border-radius: 5px;
  height: 400px;
  cursor: pointer; }
  .recipe .recipe-overlay {
    position: absolute;
    width: 100%;
    height: 100%; }
  .recipe .image-wrapper {
    width: 100%; }
    .recipe .image-wrapper img {
      width: 100%;
      height: 170px;
      object-fit: cover;
      object-position: center;
      max-height: 280px;
      border-radius: 8px 8px 0 0; }
    .recipe .image-wrapper .icon {
      position: absolute;
      top: 17.35px;
      right: 15.91px;
      font-size: 24px;
      cursor: pointer; }
      .recipe .image-wrapper .icon:hover {
        opacity: 0.5; }
  .recipe .content-wrapper {
    width: 100%;
    padding: 15px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between; }
    .recipe .content-wrapper .name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 21px;
      mix-blend-mode: normal;
      font-weight: bold;
      height: 52px;
      line-height: 26px; }
    .recipe .content-wrapper .price {
      color: #0054b1;
      font-size: 21px;
      font-weight: bold; }
    .recipe .content-wrapper .details-wrapper {
      display: flex;
      flex-direction: column;
      max-height: 100px; }
      .recipe .content-wrapper .details-wrapper .name-price-wrapper {
        display: flex;
        justify-content: space-between; }
      .recipe .content-wrapper .details-wrapper .recipe-info {
        margin-top: 10px; }
        .recipe .content-wrapper .details-wrapper .recipe-info p {
          margin: 0 !important; }
    .recipe .content-wrapper .action-wrapper {
      display: flex;
      justify-content: space-between; }
      .recipe .content-wrapper .action-wrapper .recipe-info {
        align-self: flex-start; }
        .recipe .content-wrapper .action-wrapper .recipe-info p {
          margin: 0 !important; }
        .recipe .content-wrapper .action-wrapper .recipe-info .servings {
          font-size: 16px; }
        .recipe .content-wrapper .action-wrapper .recipe-info .recipe-tags {
          display: flex;
          align-items: center; }
          .recipe .content-wrapper .action-wrapper .recipe-info .recipe-tags .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            background-size: cover;
            margin-right: 5px; }
            .recipe .content-wrapper .action-wrapper .recipe-info .recipe-tags .icon.vegetarian {
              background: url("../../../assets/vegetarian_icon.svg"); }
            .recipe .content-wrapper .action-wrapper .recipe-info .recipe-tags .icon.vegan {
              background: url("../../../assets/vegan_icon.svg"); }
            .recipe .content-wrapper .action-wrapper .recipe-info .recipe-tags .icon:last-child {
              margin-right: 0; }
      .recipe .content-wrapper .action-wrapper .product-quantity {
        align-self: flex-end; }
        .recipe .content-wrapper .action-wrapper .product-quantity .weight-select {
          width: 50%;
          height: 32px;
          display: inline-block;
          margin-top: 15px; }
      .recipe .content-wrapper .action-wrapper .add-to-basket {
        width: 100%; }
        .recipe .content-wrapper .action-wrapper .add-to-basket .add-to-basket-button {
          width: 100%;
          font-size: 14px; }
        .recipe .content-wrapper .action-wrapper .add-to-basket .add-to-basket-wrapper {
          display: inline-block;
          float: right; }
          .recipe .content-wrapper .action-wrapper .add-to-basket .add-to-basket-wrapper .basket-quantity button {
            padding: 10px; }
  @media screen and (max-width: 480px) {
    .recipe .content-wrapper .name {
      font-size: 16px; }
    .recipe .content-wrapper .action-wrapper {
      display: block; }
      .recipe .content-wrapper .action-wrapper .product-quantity .add-to-basket-button {
        width: 100%; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .recipe {
      width: 48%; } }
  @media screen and (max-width: 991px) {
    .recipe .content-wrapper .action-wrapper .recipe-info {
      display: none; }
    .recipe .content-wrapper .action-wrapper .weight-select {
      width: 44% !important; } }
  @media screen and (max-width: 1300px) {
    .recipe .content-wrapper .weight-select {
      width: 46% !important; } }
