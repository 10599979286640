.floating-textarea {
  position: relative; }

.floating-textarea label {
  position: absolute;
  top: 10px;
  left: 12px;
  font-size: 12px;
  font-family: "InterFace", sans-serif;
  font-weight: 500;
  color: #929292;
  z-index: 2;
  transition: top 0.3s ease;
  pointer-events: none; }

.floating-textarea label.is-focused {
  top: 6px;
  color: #0077be;
  z-index: 2; }

.floating-textarea label.is-not-active {
  color: #929292;
  z-index: 2; }

.floating-textarea textarea {
  height: 45px;
  min-height: 200px;
  padding-top: 20px;
  z-index: 1;
  background-color: transparent;
  position: relative;
  resize: none; }

.floating-textarea .fa-spin {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2; }

.floating-textarea textarea.has-error,
.floating-textarea select.has-error {
  border-color: indianred; }

.floating-textarea .error {
  color: indianred;
  width: 100% !important;
  line-height: 0.9;
  font-size: 12px; }

.form-control textarea {
  min-height: 200px;
  padding-top: 20px;
  z-index: 1;
  background-color: transparent;
  position: relative; }

.floating-textarea select::-ms-expand {
  display: none; }

.floating-textarea select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer; }

.floating-textarea .icon-arrow-bottom-white {
  position: absolute;
  top: 20px;
  right: 12px; }

.cart-create-account .form-group {
  margin-bottom: 25px !important; }

.cart-create-account .date-seletor-wrapper button {
  width: 100%;
  text-align: left;
  display: flex;
  border-color: #0077be;
  padding: 10px 20px 10px 5px; }

.cart-create-account .date-seletor-wrapper button .fa-calendar {
  display: none; }

.cart-create-account .date-seletor-wrapper button .fa-chevron-down {
  margin-left: auto; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  opacity: 1;
  background-color: transparent; }
