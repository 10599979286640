.change-password {
  margin: 15px 0 20px; }
  .change-password > h3 {
    margin-bottom: 5px !important; }
  .change-password button {
    min-width: 160px;
    padding: 6px 10px;
    margin-bottom: 15px; }
  .change-password .password-edit-panel {
    margin-top: 15px; }
  .change-password .password-edit-buttons {
    display: flex; }
    .change-password .password-edit-buttons button {
      flex-grow: 1;
      margin-bottom: 0; }
      .change-password .password-edit-buttons button.change-btn {
        flex-grow: 0; }
      .change-password .password-edit-buttons button .fa {
        color: inherit;
        font-size: 12px; }
