.account-wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  font-family: "InterFace", sans-serif;
  margin-top: 125px;
  font-size: 17px; }

.account-wrapper .inverse-button,
.account-wrapper .btn-primary {
  border: 1px solid #0077be;
  color: #fff;
  background-color: #0077be; }

.account-wrapper .inverse-button:hover,
.account-wrapper .btn-primary:hover {
  border: 1px solid #0077be;
  color: #0077be;
  background-color: #fff; }

.account-wrapper button.inverse {
  border: 1px solid #0077be;
  color: #0077be;
  background-color: #fff; }

.account-wrapper nav {
  background-color: #fff;
  width: 30%; }

.account-wrapper nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox; }

.account-wrapper nav ul li {
  flex-grow: 1; }

.account-wrapper nav ul li a {
  padding: 5px 5px 5px 15px;
  margin: 10px 0;
  display: inline-block;
  width: 100%;
  color: inherit;
  text-decoration: none; }

.account-wrapper nav ul li a.active {
  border-left: 7px solid #0077be; }

.account-wrapper .account-content {
  min-height: 500px;
  position: relative;
  top: -92px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 0 50px; }

.account-wrapper .account-content h3 {
  font-size: 28px;
  font-weight: bold !important;
  color: #0054b1;
  text-align: left;
  margin-top: 0;
  padding: 45px 0 20px 0; }

@media screen and (max-width: 1200px) {
  .account-wrapper nav {
    width: 40%; } }

@media screen and (max-width: 991px) {
  .account-wrapper nav {
    padding: 0 30px; }
  .account-wrapper nav ul {
    width: 100%; } }

@media screen and (max-width: 600px) {
  .account-wrapper {
    font-size: 16px;
    margin-top: 0;
    flex-direction: column; }
  .account-wrapper nav {
    padding: 0 5px;
    width: 0;
    border: none; }
  .account-wrapper nav ul {
    display: flex;
    flex-wrap: wrap;
    width: 90vw; }
  .account-wrapper nav ul li a {
    text-transform: capitalize; }
  .account-wrapper nav ul li a.active {
    border-bottom: 4px solid #0077be;
    border-left: none; }
  .account-wrapper nav ul li a span {
    display: none; }
  .account-wrapper .account-content {
    padding: 0;
    top: 0;
    width: 100%; }
  .account-wrapper .account-content .togglePanel,
  .account-wrapper .account-content .content {
    width: 100%; }
  .account-details .contact-banner {
    height: 0; }
  .account-wrapper .account-content h3 {
    padding: 25px 0 10px 0; } }

.account-wrapper .account-content .subscription-status {
  position: absolute;
  top: 20px;
  margin: 0 auto;
  left: 0;
  text-align: center;
  right: 0;
  font-size: 18px;
  font-family: "InterFace", sans-serif; }

.account-wrapper .account-content .welcome-back-text {
  position: absolute;
  top: 30px;
  margin: 0 auto;
  font-size: 18px;
  font-family: "InterFace", sans-serif; }

.cancel-subscription .title {
  color: #b8d388;
  font-size: 19px;
  font-family: "InterFace", sans-serif; }

.account-details {
  margin-top: 75px; }
