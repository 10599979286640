.account-deliveries-page-wrapper {
  min-height: 500px; }
  .account-deliveries-page-wrapper .account-deliveries-page .delivery-item {
    border-bottom: 1px solid rgba(193, 154, 93, 0.5);
    border-left: 7px solid #fff;
    padding: 10px 0; }
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item.title {
      font-weight: bold; }
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item.active {
      border-left: 7px solid #0077be; }
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .row {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0; }
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .row .column {
        text-align: center;
        flex: 1; }
      @media screen and (max-width: 480px) {
        .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .row {
          font-size: 12px;
          padding-left: 15px;
          padding-right: 15px; } }
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .date,
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .meal,
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .edit-text {
      display: flex;
      align-items: center;
      margin-bottom: 2px; }
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .date .calendar-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .date .info-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .date .autorenew-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .date .basket-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .meal .calendar-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .meal .info-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .meal .autorenew-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .meal .basket-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .edit-text .calendar-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .edit-text .info-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .edit-text .autorenew-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .edit-text .basket-icon {
        width: 20px;
        height: 20px;
        margin-right: 7px;
        color: #0077be; }
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .date .info-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .meal .info-icon,
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .edit-text .info-icon {
        background-image: url("./info-icon.svg"); }
    .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 15px 10px 15px 10px; }
      .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .button-wrapper .btn {
        border: 1px solid #0077be;
        color: #fff;
        background-color: #0077be;
        width: 30%; }
        .account-deliveries-page-wrapper .account-deliveries-page .delivery-item .button-wrapper .btn:hover {
          color: #0077be;
          background-color: #fff; }
  .account-deliveries-page-wrapper .delivery-items-wrapper {
    margin-left: 15px; }
    .account-deliveries-page-wrapper .delivery-items-wrapper .item {
      display: flex;
      flex-direction: row; }
      @media screen and (max-width: 480px) {
        .account-deliveries-page-wrapper .delivery-items-wrapper .item {
          font-size: 12px;
          padding-left: 2px;
          padding-right: 2px; } }
      .account-deliveries-page-wrapper .delivery-items-wrapper .item.title {
        font-weight: bold;
        margin-bottom: 15px; }
      .account-deliveries-page-wrapper .delivery-items-wrapper .item .item-column {
        display: flex;
        flex-direction: column;
        flex: 1; }
        .account-deliveries-page-wrapper .delivery-items-wrapper .item .item-column.product-name {
          text-align: left;
          flex-grow: 1.5; }
        .account-deliveries-page-wrapper .delivery-items-wrapper .item .item-column.product-price {
          text-align: left; }
        .account-deliveries-page-wrapper .delivery-items-wrapper .item .item-column.product-quantity {
          text-align: center; }

.load-more-deliveries {
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 0; }
  .load-more-deliveries button {
    padding: 7px 20px; }

.delivery-recipes-wrapper {
  width: 100%;
  position: absolute;
  min-height: 250px;
  background-color: indianred;
  top: 100%;
  left: 0; }

.copy-order-date-picker p {
  text-align: center;
  font-weight: 700; }

.copy-order-date-picker .react-datepicker__portal {
  position: unset !important;
  width: unset !important;
  height: unset !important;
  background-color: unset !important;
  left: unset !important;
  top: unset !important; }

.copy-order-date-picker .react-datepicker {
  width: 100% !important;
  z-index: 0;
  box-shadow: none !important;
  border-radius: 5px;
  border: none !important;
  position: relative !important;
  top: 0 !important; }
  .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__header {
    background-color: #fff !important;
    border-bottom: none; }
    .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
      font-family: "InterFace", sans-serif;
      color: #262626;
      font-size: 16px; }
  .copy-order-date-picker .react-datepicker .react-datepicker__month-container .custom-date-picker-header {
    display: flex;
    padding-left: 5px; }
    .copy-order-date-picker .react-datepicker .react-datepicker__month-container .custom-date-picker-header select {
      font-family: "InterFace", sans-serif;
      font-size: 18px;
      color: #262626;
      background-color: transparent;
      border: none; }
  .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month {
    font-family: "InterFace", sans-serif; }
    .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
      display: flex;
      justify-content: space-between; }
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day-name,
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__time-name {
        border-radius: 100%;
        height: 1.7rem;
        max-height: 1.7rem;
        max-width: 1.7rem;
        line-height: normal !important;
        -ms-flex-align: center;
        align-items: center;
        margin: 5px 0;
        font-size: 17px; }
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled {
        color: rgba(38, 38, 38, 0.4); }
  @media screen and (min-width: 300px) {
    .copy-order-date-picker .react-datepicker {
      padding: 20px; }
      .copy-order-date-picker .react-datepicker .react-datepicker__day,
      .copy-order-date-picker .react-datepicker .react-datepicker__day-name {
        padding: 0 !important; } }
