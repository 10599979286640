.user-summary-secondary {
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  height: fit-content; }
  .user-summary-secondary .panel-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ddd; }
  .user-summary-secondary .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .user-summary-secondary .widget {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 328px;
    height: 352px;
    left: 0;
    font-family: 'InterFace', sans-serif;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden; }
    .user-summary-secondary .widget h1 {
      font-size: 32px;
      font-weight: bold !important;
      text-align: center;
      line-height: 32px;
      padding: 0 30px;
      margin-top: 15px; }
    .user-summary-secondary .widget .prod-badge {
      position: absolute;
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      background-color: #777;
      border-radius: 20px;
      right: 0;
      margin: 15px; }
    .user-summary-secondary .widget img {
      width: 100%;
      height: 224px; }
    .user-summary-secondary .widget a {
      font-size: 16px;
      color: #0054b1;
      text-decoration: underline; }
  .user-summary-secondary .featured-product .prod-badge {
    background-color: #c19a5d; }
  .user-summary-secondary .featured-product h1 {
    color: #c19a5d; }
  .user-summary-secondary .learn-more .prod-badge {
    background-color: #417505; }
  .user-summary-secondary .learn-more h1 {
    color: #417505; }
  .user-summary-secondary .contact-us {
    background-image: url("../../assets/bbsImages/Haddock loin -min.jpg");
    background-size: cover;
    background-position: center;
    justify-content: inherit;
    padding: 15px 0 15px 0 !important; }
    .user-summary-secondary .contact-us p,
    .user-summary-secondary .contact-us h1,
    .user-summary-secondary .contact-us a {
      color: #fff;
      z-index: 2; }
  .user-summary-secondary .contact-us::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .user-summary-secondary .container {
      flex-wrap: nowrap; }
    .user-summary-secondary .widget {
      width: 288px; } }
  @media screen and (max-width: 991px) {
    .user-summary-secondary .widget {
      display: none; } }
