.postcode-lookup .form-group-wrapper {
  display: flex; }

.postcode-lookup .form-group-wrapper div {
  width: 100%; }

.postcode-lookup .form-group-wrapper div input {
  margin-bottom: 0 !important; }

.postcode-lookup .form-group-wrapper .form-group {
  margin-right: 10px; }

.postcode-lookup .form-group-wrapper .button-wrapper {
  margin-left: 10px;
  margin-top: 0; }

.postcode-lookup .form-group-wrapper .button-wrapper button {
  height: 45px; }

.postcode-lookup .form-group button {
  height: 45px; }

.postcode-lookup .manual-address-toggle {
  color: #b9d287;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted; }

.existing-address-select {
  position: relative; }
  .existing-address-select .select-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 20px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer; }
    .existing-address-select .select-title .text {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .existing-address-select .select-title .address-icon {
      margin-left: 15px; }
  .existing-address-select .existing-address-list {
    position: absolute;
    max-height: 150px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(9, 30, 66, 0.25);
    border: 1px solid rgba(9, 30, 66, 0.25);
    z-index: 3;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    width: auto;
    min-width: 100%;
    margin: auto;
    left: 0;
    top: 100%; }
    .existing-address-select .existing-address-list li {
      position: relative;
      padding: 0 15px;
      cursor: pointer; }
      .existing-address-select .existing-address-list li .address-target {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      .existing-address-select .existing-address-list li .content {
        padding: 10px 0;
        border-bottom: 1px solid rgba(9, 30, 66, 0.25);
        white-space: nowrap; }
      .existing-address-select .existing-address-list li:hover {
        background-color: #eee; }
      .existing-address-select .existing-address-list li:last-child .content {
        border-bottom: none; }
