.page-not-found {
  position: relative;
  height: 100%; }
  .page-not-found .banner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: url("../../assets/bbsImages/Salmon Fillets 2-min.jpg") no-repeat 0 0;
    background-position: center;
    background-size: cover; }
    .page-not-found .banner::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3); }
    .page-not-found .banner .content {
      margin: auto;
      text-align: center;
      z-index: 2; }
      .page-not-found .banner .content h3 {
        color: #fff;
        margin: 0 0 15px;
        width: 100%;
        font-size: 28px; }
      .page-not-found .banner .content p {
        width: 100%;
        margin: 0;
        font-size: 18px;
        color: #fff; }
      .page-not-found .banner .content button {
        width: 185px;
        padding: 10px 25px;
        font-size: 16px;
        margin-top: 20px; }
        .page-not-found .banner .content button .text {
          margin-left: 15px; }
      .page-not-found .banner .content a {
        width: 185px;
        display: inline-block;
        font-size: 16px;
        padding: 6px 20px;
        margin-top: 20px;
        background-color: #fff;
        color: #fff;
        border-radius: 5px;
        border: 1px solid #0077be;
        text-decoration: none;
        cursor: pointer; }
        .page-not-found .banner .content a:hover, .page-not-found .banner .content a.active {
          background-color: #0077be;
          border: 1px solid #0077be;
          transition: 0.5s; }
        .page-not-found .banner .content a .text {
          margin-left: 15px; }
