.mini-basket .underlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 99;
  top: 0;
  left: 0;
  cursor: pointer; }

.mini-basket .slide-out-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: -830px;
  width: 824px;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  transition: right 0.5s ease;
  overflow: auto;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.25); }
  @media screen and (max-width: 991px) {
    .mini-basket .slide-out-panel {
      width: 100%;
      right: -1000px; } }
  .mini-basket .slide-out-panel button.clear-basket {
    border-radius: 0;
    border: 1px solid #0077be; }
    .mini-basket .slide-out-panel button.clear-basket:hover {
      border: 1px solid #0077be;
      background-color: #fff;
      color: #0077be; }

.mini-basket.show .underlay {
  display: block; }

.mini-basket.show .slide-out-panel {
  right: 0; }

.mini-basket .panel-header {
  display: -webkit-flex;
  display: flex;
  background-color: #f5f5f5;
  position: relative; }
  .mini-basket .panel-header .left {
    display: flex;
    align-items: center;
    padding: 0 30px;
    background-color: #fff;
    height: 66px;
    color: #262626;
    font-family: "InterFace", sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 36px; }
    @media screen and (max-width: 991px) {
      .mini-basket .panel-header .left {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (max-width: 767px) {
      .mini-basket .panel-header .left {
        font-family: "InterFace", sans-serif;
        font-size: 22px; } }
  .mini-basket .panel-header .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    height: 66px;
    background-color: rgba(38, 38, 38, 0.6);
    border: 1px solid #979797;
    border-top: none;
    border-right: none;
    padding: 10px 40px;
    text-align: right;
    cursor: pointer; }
    .mini-basket .panel-header .right span {
      font-size: 16px; }
    .mini-basket .panel-header .right .mobile-close {
      display: none; }
    @media screen and (max-width: 767px) {
      .mini-basket .panel-header .right {
        background-color: #fff; } }
    @media screen and (max-width: 991px) {
      .mini-basket .panel-header .right {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (min-width: 992px) {
      .mini-basket .panel-header .right .icon-times {
        display: none; } }
  @media screen and (max-width: 767px) {
    .mini-basket .panel-header {
      display: block;
      margin-bottom: 0; }
      .mini-basket .panel-header .left {
        width: 100%;
        height: 44px;
        padding: 10px 30px; }
      .mini-basket .panel-header .right {
        position: absolute;
        top: 0;
        right: 20px;
        box-shadow: none;
        border: none;
        padding-right: 0;
        height: 40px; }
        .mini-basket .panel-header .right .icon-close {
          display: none; }
        .mini-basket .panel-header .right .mobile-close {
          display: block;
          margin-left: 5px;
          color: #007aff; } }
  @media screen and (max-width: 767px) {
    .mini-basket .panel-header.editing {
      background-color: #fff;
      position: fixed;
      z-index: 2;
      width: 100%; } }

.mini-basket .edit-order-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 30px 20px; }
  .mini-basket .edit-order-header h3 {
    margin: 10px 0 2px;
    font-family: "InterFace", sans-serif;
    font-size: 22px;
    line-height: 36px;
    color: #262626; }
    .mini-basket .edit-order-header h3 span {
      font-size: 22px; }
  .mini-basket .edit-order-header span {
    color: #262626;
    font-size: 14px;
    line-height: 18px;
    font-family: "InterFace", sans-serif; }
  .mini-basket .edit-order-header .clear-all {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 251px;
    height: 44px;
    background-color: #0077be;
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em; }
    .mini-basket .edit-order-header .clear-all:hover {
      border: 1px solid #007435;
      background-color: #007435; }
  @media screen and (max-width: 767px) {
    .mini-basket .edit-order-header {
      display: block;
      padding-top: 0; }
      .mini-basket .edit-order-header h3 {
        font-size: 18px;
        line-height: 24px; }
        .mini-basket .edit-order-header h3 span {
          font-size: 18px; } }

.mini-basket .subscription-preferences {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 34px 20px 0; }
  .mini-basket .subscription-preferences .portions,
  .mini-basket .subscription-preferences .frequency,
  .mini-basket .subscription-preferences .starting-on {
    width: 32%; }
    .mini-basket .subscription-preferences .portions label,
    .mini-basket .subscription-preferences .frequency label,
    .mini-basket .subscription-preferences .starting-on label {
      font-size: 16px; }
  .mini-basket .subscription-preferences .starting-on {
    padding-bottom: 10px; }
  .mini-basket .subscription-preferences .portions .info {
    display: block;
    font-size: 14px;
    color: #262626;
    margin-top: 5px; }
  .mini-basket .subscription-preferences .content.not-selected {
    border: 1px solid #dc0000;
    height: 55px;
    color: #dc0000; }

.mini-basket .basket-warning {
  display: flex;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 40px; }
  .mini-basket .basket-warning .icon {
    font-size: 20px;
    color: #2563ad;
    margin-right: 15px; }
  .mini-basket .basket-warning h4 {
    margin: 0;
    font-size: 16px;
    font-family: "InterFace", sans-serif;
    color: #2563ad; }

.mini-basket .recipes-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 30px 0;
  margin-bottom: 100px; }
  .mini-basket .recipes-wrapper h3 {
    font-family: "InterFace", sans-serif;
    font-size: 22px;
    line-height: 36px;
    margin: 0;
    color: #262626; }
  .mini-basket .recipes-wrapper .mini-basket-item {
    padding: 10px 0;
    border-bottom: 0.5px solid rgba(193, 154, 93, 0.5); }
  @media screen and (max-width: 479px) {
    .mini-basket .recipes-wrapper {
      margin-bottom: 205px; } }

.mini-basket .confirm-basket {
  position: absolute;
  width: 824px;
  background-color: #fff;
  bottom: 0;
  padding: 20px 30px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.15); }
  .mini-basket .confirm-basket .per-serving,
  .mini-basket .confirm-basket .delivery,
  .mini-basket .confirm-basket .total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px; }
    .mini-basket .confirm-basket .per-serving label,
    .mini-basket .confirm-basket .delivery label,
    .mini-basket .confirm-basket .total label {
      font-weight: 500;
      margin-bottom: 0; }
    .mini-basket .confirm-basket .per-serving span,
    .mini-basket .confirm-basket .delivery span,
    .mini-basket .confirm-basket .total span {
      min-width: 66px;
      margin-left: 5px;
      text-align: right; }
  .mini-basket .confirm-basket .per-serving {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px; }
  .mini-basket .confirm-basket .total {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px; }
    .mini-basket .confirm-basket .total label {
      font-weight: 600; }
  .mini-basket .confirm-basket .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .mini-basket .confirm-basket .button-wrapper .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 20px;
      padding: 16px 25px;
      outline: none !important;
      box-shadow: none !important; }
      .mini-basket .confirm-basket .button-wrapper .btn .icon {
        font-size: 9px;
        line-height: 2px; }
      .mini-basket .confirm-basket .button-wrapper .btn.browse-recipes {
        color: #fff;
        border: 1px solid #0077be;
        background-color: #0077be;
        padding: 16px 35px !important; }
        .mini-basket .confirm-basket .button-wrapper .btn.browse-recipes:hover {
          background-color: #fff;
          color: #0077be; }
      .mini-basket .confirm-basket .button-wrapper .btn.continue-to-checkout {
        color: #fff;
        background-color: #0077be;
        font-size: 18px;
        font-family: "Interface", sans-serif;
        font-weight: 400; }
        .mini-basket .confirm-basket .button-wrapper .btn.continue-to-checkout:hover {
          border: 1px solid #0077be;
          background-color: #fff;
          color: #0077be !important;
          padding: 12px 25px; }
      .mini-basket .confirm-basket .button-wrapper .btn[disabled] {
        background-color: #0077be;
        border: 1px solid transparent; }
  @media screen and (max-width: 991px) {
    .mini-basket .confirm-basket {
      width: 100%; } }
  @media screen and (max-width: 479px) {
    .mini-basket .confirm-basket .per-serving {
      justify-content: center; }
    .mini-basket .confirm-basket .button-wrapper {
      flex-direction: column; }
      .mini-basket .confirm-basket .button-wrapper .btn {
        width: 100%;
        margin-bottom: 10px; } }

@media screen and (max-width: 991px) {
  .mini-basket .desktop-basket-preferences {
    display: none; }
  .mini-basket .mobile-basket-preferences {
    padding: 0 30px; }
    .mini-basket .mobile-basket-preferences .subscription-preferences {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
      justify-content: space-between; }
      .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on {
        width: 100%;
        position: relative; }
        .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on label {
          font-size: 18px; }
        .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content {
          display: inline-block;
          padding-left: 10px; }
          .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .change-date .text {
            font-family: "InterFace", sans-serif;
            color: #0077be;
            cursor: pointer;
            text-decoration: underline; }
            .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .change-date .text:hover {
              text-decoration: none; }
          .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker {
            position: absolute;
            width: 256px;
            left: 0;
            z-index: 1; }
            .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .react-datepicker__header {
              background-color: #fff !important; }
              .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
                color: #262626; }
            .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .custom-date-picker-header {
              display: flex;
              padding-left: 5px; }
              .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .custom-date-picker-header select {
                font-size: 18px;
                color: #262626;
                background-color: transparent;
                border: none; }
            .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
              display: flex;
              justify-content: space-between; }
              .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day-name,
              .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
              .mini-basket .mobile-basket-preferences .subscription-preferences .starting-on .content .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__time-name {
                border-radius: 100%;
                height: 1.7rem;
                max-height: 1.7rem;
                max-width: 1.7rem;
                line-height: normal !important;
                -ms-flex-align: center;
                align-items: center;
                margin: 5px 0; }
      .mini-basket .mobile-basket-preferences .subscription-preferences label {
        font-size: 16px;
        font-family: "InterFace", sans-serif; }
      .mini-basket .mobile-basket-preferences .subscription-preferences .portions,
      .mini-basket .mobile-basket-preferences .subscription-preferences .frequency {
        width: 48%; } }

@media screen and (min-width: 992px) {
  .mini-basket .mobile-basket-preferences {
    display: none; } }

.mini-basket-item {
  display: flex; }
  .mini-basket-item .add-to-basket-button {
    display: none; }
  .mini-basket-item .image-wrapper {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: 92px;
    margin-right: 15px; }
    .mini-basket-item .image-wrapper img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      border-radius: 5px; }
      .mini-basket-item .image-wrapper img:hover {
        cursor: pointer; }
  .mini-basket-item .basket-detail-wrapper {
    flex-grow: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .mini-basket-item .basket-detail-wrapper .name-wrapper {
      flex-grow: 1; }
      .mini-basket-item .basket-detail-wrapper .name-wrapper .name {
        font-size: 18px;
        color: #262626; }
        .mini-basket-item .basket-detail-wrapper .name-wrapper .name:hover {
          text-decoration: underline;
          color: #0077be;
          cursor: pointer; }
      .mini-basket-item .basket-detail-wrapper .name-wrapper .servings {
        font-size: 16px;
        color: #262626; }
    .mini-basket-item .basket-detail-wrapper .weight-select {
      display: none; }
  .mini-basket-item ul {
    display: flex;
    list-style: none;
    margin: 5px 0 0;
    padding: 0; }
    .mini-basket-item ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 36px;
      border: 1px solid #ccc;
      cursor: pointer; }
      .mini-basket-item ul li:hover {
        background-color: #f5f5f5; }
      .mini-basket-item ul li.increase {
        border-radius: 0 3px 3px 0; }
        .mini-basket-item ul li.increase.disabled {
          opacity: 0.5;
          cursor: default; }
          .mini-basket-item ul li.increase.disabled:hover {
            background-color: transparent; }
      .mini-basket-item ul li.decrease {
        border-right: none;
        border-radius: 3px 0 0 3px; }
      .mini-basket-item ul li.quantity {
        font-size: 12px;
        border-right: none;
        cursor: default; }
        .mini-basket-item ul li.quantity:hover {
          background-color: transparent; }
      .mini-basket-item ul li .bar {
        display: block;
        width: 9px;
        height: 2px;
        background-color: #3f4241;
        line-height: normal; }
      .mini-basket-item ul li .icon {
        font-size: 12px; }
  @media screen and (max-width: 991px) {
    .mini-basket-item .basket-detail-wrapper {
      flex-direction: column;
      align-items: flex-end; } }
