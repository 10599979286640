.personal-detail .item {
  display: grid;
  grid-template-columns: 25px auto 100px;
  align-items: center;
  margin-bottom: 10px; }
  .personal-detail .item .icon-wrapper {
    color: #c0d693; }
    .personal-detail .item .icon-wrapper .email-icon {
      width: 15px;
      height: 15px; }

.personal-detail .inline-edit-panel {
  margin-bottom: 20px; }
  .personal-detail .inline-edit-panel .btn {
    margin-top: 10px;
    background-color: #b9d287; }
