.shopping-cart-summary {
  margin-bottom: 50px; }
  .shopping-cart-summary .box-total {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 18px; }
    .shopping-cart-summary .box-total:last-child {
      border-bottom: none; }
    .shopping-cart-summary .box-total .title {
      flex-grow: 1;
      color: #4a4a4a;
      font-weight: bold; }
    .shopping-cart-summary .box-total .price {
      font-size: 21px;
      color: #0054b1;
      font-weight: bold; }
  .shopping-cart-summary .gold-underline {
    border-bottom: 1px solid rgba(193, 154, 93, 0.5); }
