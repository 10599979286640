@media (max-width: 1300px) {
  .payment-confirmation-page-wrapper {
    margin-top: 75px; } }

.payment-confirmation-page {
  position: relative;
  min-height: 600px;
  padding: 200px 190px 80px; }
  .payment-confirmation-page .content-wrapper.right {
    display: block; }
  .payment-confirmation-page .content-wrapper .item {
    display: flex; }
  .payment-confirmation-page .confirmation-page-content {
    display: flex !important; }
  .payment-confirmation-page .left {
    margin-bottom: 25px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .payment-confirmation-page .left .error-wrapper {
      margin-top: 1rem; }
    .payment-confirmation-page .left .delivery-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      color: rgba(34, 38, 37, 0.87);
      padding-bottom: 23px;
      margin-bottom: 22px; }
      .payment-confirmation-page .left .delivery-details .content-wrapper {
        width: 50% !important;
        display: flex;
        align-items: flex-start; }
        .payment-confirmation-page .left .delivery-details .content-wrapper:first-child {
          flex-grow: 1; }
        .payment-confirmation-page .left .delivery-details .content-wrapper.left, .payment-confirmation-page .left .delivery-details .content-wrapper.right {
          display: block; }
        .payment-confirmation-page .left .delivery-details .content-wrapper .item {
          display: flex;
          margin-bottom: 10px;
          justify-content: center; }
        .payment-confirmation-page .left .delivery-details .content-wrapper .icon {
          min-width: 25px;
          font-size: 25px;
          margin-right: 15px;
          color: #4a4a4a; }
        .payment-confirmation-page .left .delivery-details .content-wrapper .delivery-content {
          font-size: 16px; }
          .payment-confirmation-page .left .delivery-details .content-wrapper .delivery-content .name {
            line-height: 24px; }
        @media (max-width: 480px) {
          .payment-confirmation-page .left .delivery-details .content-wrapper {
            width: 100%;
            margin-bottom: 20px; }
            .payment-confirmation-page .left .delivery-details .content-wrapper:last-child {
              margin-bottom: 0; } }
    .payment-confirmation-page .left .button-wrapper {
      display: flex;
      flex-wrap: wrap; }
      .payment-confirmation-page .left .button-wrapper .btn {
        color: #fff;
        background-color: #0077be;
        font-size: 18px;
        font-family: "Interface", sans-serif;
        font-weight: 400;
        flex-grow: 1;
        display: inline-block;
        margin-right: 8px;
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
        box-shadow: none; }
        .payment-confirmation-page .left .button-wrapper .btn.inverse-button {
          background-color: #0077be;
          color: #fff; }
          .payment-confirmation-page .left .button-wrapper .btn.inverse-button:hover {
            border: 1px solid #0077be;
            background-color: #fff;
            color: #2a2e39 !important;
            padding: 5px 11px;
            transition: none; }
        .payment-confirmation-page .left .button-wrapper .btn:last-child {
          margin-right: 0; }
        @media (max-width: 480px) {
          .payment-confirmation-page .left .button-wrapper .btn {
            width: 100%;
            margin: 0 0 20px; }
            .payment-confirmation-page .left .button-wrapper .btn:last-child {
              margin-bottom: 0; } }
  .payment-confirmation-page .right {
    padding: 40px 10px 20px;
    flex-grow: 1; }
  @media (min-width: 992px) {
    .payment-confirmation-page .left {
      padding-right: 25px; }
    .payment-confirmation-page .right {
      padding-left: 25px; } }
  @media screen and (max-width: 1300px) {
    .payment-confirmation-page {
      padding: 40px 100px 80px; }
      .payment-confirmation-page h1,
      .payment-confirmation-page h2 {
        font-size: 20px !important; }
      .payment-confirmation-page .cart-header > ul > li {
        width: 150px !important; }
        .payment-confirmation-page .cart-header > ul > li span {
          text-align: center; } }
  @media screen and (max-width: 991px) {
    .payment-confirmation-page {
      padding: 40px 15px; }
      .payment-confirmation-page .cart-content .left-side {
        margin-right: 20px; }
      .payment-confirmation-page .cart-content .right-side .basket-summary .checkout-header {
        display: block; }
        .payment-confirmation-page .cart-content .right-side .basket-summary .checkout-header .sub-title {
          margin-bottom: 30px; }
        .payment-confirmation-page .cart-content .right-side .basket-summary .checkout-header .portion-size {
          width: 230px;
          margin: auto; } }
  @media screen and (max-width: 768px) {
    .payment-confirmation-page .cart-header {
      display: none; }
    .payment-confirmation-page .confirmation-page-content {
      flex-direction: column; }
      .payment-confirmation-page .confirmation-page-content .left {
        width: 100%; }
    .payment-confirmation-page .order-summary-wrapper .item {
      font-size: 16px; }
    .payment-confirmation-page .order-summary-wrapper .value {
      font-size: 18px; } }
  .payment-confirmation-page .cart-header {
    width: 60%;
    background-color: #fff;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center; }
    .payment-confirmation-page .cart-header ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center; }
      .payment-confirmation-page .cart-header ul li {
        width: 200px;
        display: flex;
        align-items: center; }
        .payment-confirmation-page .cart-header ul li:last-child {
          width: auto; }
        .payment-confirmation-page .cart-header ul li.delivery-step {
          cursor: pointer; }
          .payment-confirmation-page .cart-header ul li.delivery-step span:hover {
            text-decoration: underline; }
        .payment-confirmation-page .cart-header ul li span,
        .payment-confirmation-page .cart-header ul li .icon {
          flex-grow: 1;
          font-size: 16px;
          color: #ccc;
          color: #0054b1; }
        .payment-confirmation-page .cart-header ul li .icon {
          font-size: 10px; }
        .payment-confirmation-page .cart-header ul li .checked-icon {
          width: 13px;
          height: 13px; }
  @media screen and (max-width: 991px) {
    .payment-confirmation-page .cart-header {
      width: 80%; } }
  @media screen and (max-width: 680px) {
    .payment-confirmation-page .cart-header {
      display: none; } }
  .payment-confirmation-page .thank-you-message {
    background-color: #fff;
    padding: 24.5px;
    border-radius: 6px 6px 0 0;
    text-align: center;
    position: relative; }
    .payment-confirmation-page .thank-you-message h1 {
      color: #0054b1;
      font-size: 28px;
      font-weight: bold !important;
      line-height: 28px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      margin-top: 15px; }
      .payment-confirmation-page .thank-you-message h1 .icon-orderconfirmation {
        font-size: 25px;
        margin-right: 15px; }
    .payment-confirmation-page .thank-you-message .text {
      color: #4a4a4a;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      text-align: center; }
      .payment-confirmation-page .thank-you-message .text .order-number {
        margin-left: 5px; }
  .payment-confirmation-page .order-summary-wrapper {
    background-color: rgba(255, 255, 255, 0.84); }
    .payment-confirmation-page .order-summary-wrapper .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 25px; }
      .payment-confirmation-page .order-summary-wrapper .header h2 {
        flex-grow: 1;
        margin: 0;
        color: #0054b1;
        font-size: 28px;
        font-weight: bold !important;
        line-height: 28px;
        margin-bottom: 25px; }
      .payment-confirmation-page .order-summary-wrapper .header .portions {
        color: rgba(34, 38, 37, 0.87);
        font-size: 16px;
        line-height: 24px; }
      @media (max-width: 480px) {
        .payment-confirmation-page .order-summary-wrapper .header h2,
        .payment-confirmation-page .order-summary-wrapper .header .portions {
          width: 100%; } }
    .payment-confirmation-page .order-summary-wrapper .summary {
      margin-bottom: 24px; }
      .payment-confirmation-page .order-summary-wrapper .summary .item-row {
        display: flex;
        justify-content: center;
        font-size: 16px;
        color: rgba(34, 38, 37, 0.87);
        line-height: 24px;
        padding: 15px 0; }
        .payment-confirmation-page .order-summary-wrapper .summary .item-row .item {
          flex-grow: 1;
          color: #4a4a4a;
          font-weight: bold;
          font-size: 18px; }
        .payment-confirmation-page .order-summary-wrapper .summary .item-row .value {
          font-size: 21px;
          color: #0054b1;
          font-weight: bold; }
        .payment-confirmation-page .order-summary-wrapper .summary .item-row.total {
          border-bottom: none;
          padding-bottom: 0;
          border-top: 1px solid rgba(193, 154, 93, 0.5); }
        .payment-confirmation-page .order-summary-wrapper .summary .item-row:first-child {
          padding-top: 0; }
    .payment-confirmation-page .order-summary-wrapper .dpd-delivery-text {
      width: 60%;
      margin: 30px auto 0;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .payment-confirmation-page .order-summary-wrapper .dpd-delivery-text {
          width: 100%; } }
  .payment-confirmation-page .side-banner {
    display: block;
    margin-bottom: 25px; }
    .payment-confirmation-page .side-banner img {
      border-radius: 6px; }
  .payment-confirmation-page .payment-failed {
    width: fit-content;
    margin: 0 auto; }
    .payment-confirmation-page .payment-failed .title {
      margin-bottom: 10px; }
    .payment-confirmation-page .payment-failed .message:last-of-type {
      margin-bottom: 10px; }
    .payment-confirmation-page .payment-failed .btn {
      margin: 20px auto;
      width: fit-content;
      left: 0;
      right: 0;
      display: block;
      color: black; }
