.faq {
  position: relative;
  min-height: 500px;
  padding: 0; }
  .faq .faq-content {
    margin-top: 75px;
    display: block;
    position: relative;
    width: 65%; }
    .faq .faq-content .faq-header {
      width: 65%;
      background-color: #fff;
      margin-top: 33px;
      border-radius: 5px;
      padding: 0 40px; }
      .faq .faq-content .faq-header h1 {
        font-size: 28px;
        font-weight: bold !important;
        color: #0054b1;
        text-align: left;
        margin-top: 0; }
      .faq .faq-content .faq-header h4 {
        color: #4a4a4a;
        font-weight: bold !important;
        font-size: 18px;
        text-align: left;
        padding-top: 27px; }

.faq h1,
.faq h4 {
  text-align: center; }

.faq h4 {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 40px;
  color: #777;
  font-size: 16px; }

.faq h1 {
  margin-top: 5px;
  margin-bottom: 60px; }

.whats-in-list span {
  display: block; }

.faq-title {
  background-color: #4a4a4a !important;
  padding: 20px 25px; }

.faq .panel-heading,
.faq .panel-body {
  padding: 20px 60px 20px 15px; }

.panel-default > .panel-heading {
  color: inherit; }

.faq h3 {
  margin: 0;
  font-size: 21px; }

.contact-title .floating-input {
  width: 25%; }

.dot-list li {
  list-style: square; }

#faqs .panel-body {
  font-size: 16px; }

.panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0; }

.faq .question {
  background-color: #fff; }

.faq .question,
.faq .faq-title {
  border-radius: 0; }

.faq .panel {
  border-radius: 0;
  position: relative; }

.question h3 {
  color: #4a4a4a; }

.panel-title > a {
  color: #4a4a4a; }

#faqs a {
  color: #4a4a4a; }

#faqs a:focus {
  text-decoration: none; }

.contact-banner {
  position: absolute;
  background-image: url("../../assets/bbsImages/Sweet chilli salmon .jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 105px;
  width: 100%;
  z-index: -1; }

.contact-banner::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 119, 190, 0.73); }

.faq-form-content {
  padding-top: 60px; }

.form-field-wrapper {
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.form-field-wrapper .left-input-row {
  width: 50%;
  margin-right: 15px; }

.form-field-wrapper .right-input-row {
  width: 50%;
  margin-left: 15px; }

.form-field-wrapper .entry-input {
  margin-bottom: 24px;
  position: relative; }

.required::after {
  content: "*";
  color: #ce3838;
  font-weight: 500;
  font-size: 16px; }

.input-row-full-width {
  width: 100%; }

.your-details h3 {
  margin-bottom: 24px; }

.faq-form-content h3 {
  margin-bottom: 24px; }

.contact-details-wrapper {
  background-color: rgba(0, 137, 63, 0.11);
  text-align: center;
  padding: 25px; }

.contact-details-wrapper h3 {
  margin-bottom: 40px; }

.contact-details-wrapper h4 {
  color: inherit;
  font-size: 18px;
  padding-top: 0;
  margin-bottom: 10px; }

.contact-details-wrapper img {
  padding-bottom: 15px; }

.faq-form textarea {
  display: block;
  width: 100%;
  height: 198px;
  margin-bottom: 0;
  resize: none; }

.contact-details-wrapper .call-us {
  margin-bottom: 40px; }

.contact-details-wrapper .email-us a {
  color: inherit;
  word-break: break-word; }

.contact-title input {
  width: 25%; }

.entry-input.contact-title {
  margin-right: 30px; }

.form-submit {
  padding: 10px 20px;
  width: 50%;
  font-size: 16px;
  color: #fff; }

.form-submit:hover {
  color: inherit; }

.contact-details-wrapper .call-us::before {
  content: "";
  background-size: cover;
  height: 24px;
  width: 24px; }

.contact-details-wrapper .email-us::before {
  content: "";
  background-size: cover;
  height: 24px;
  width: 24px; }

.panel-title > a::before {
  content: "";
  position: absolute;
  top: 28px;
  right: 25px;
  background-image: url("./arrow-down.svg") !important;
  background-size: cover;
  height: 10px;
  width: 16px;
  transform: rotate(180deg); }

.collapsed::before {
  content: "";
  position: absolute;
  top: 28px;
  right: 25px;
  background-image: url("./arrow-down.svg") !important;
  background-size: cover;
  height: 10px;
  width: 16px;
  transform: rotate(0deg) !important; }

.panel-collapse.collapse.in::after {
  content: ""; }

.your-details {
  padding-bottom: 40px; }

@media screen and (max-width: 767px) {
  .faq-form {
    padding-left: 0;
    padding-right: 0; }
  .contact-details {
    padding-right: 0;
    padding-left: 0; }
  .contact-title input {
    width: 50%; } }

@media screen and (min-width: 992px) {
  .faq-form {
    padding-left: 0; }
  .contact-details {
    padding-right: 0; } }

@media screen and (max-width: 992px) {
  .faq-content {
    width: 100% !important; }
    .faq-content h3 {
      font-size: 16px; }
  .faq-header {
    width: 100% !important; } }
