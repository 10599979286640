.action-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.product-quantity {
  position: relative; }
  .product-quantity .basket-quantity {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    margin-top: 5px; }
    .product-quantity .basket-quantity p {
      margin: 0 !important; }
    .product-quantity .basket-quantity button {
      width: 32px;
      height: 32px;
      background-color: #fff;
      border: 1px solid #fff !important;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      color: #a4a4a4;
      font-size: 24px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .product-quantity .basket-quantity button.quantity-decrement {
        padding-top: 6px !important; }
      .product-quantity .basket-quantity button:hover {
        color: #0054b1;
        background-color: #fff; }
    .product-quantity .basket-quantity .quantity-count {
      width: 45px !important;
      height: 32px !important;
      border-radius: 5px !important;
      border: 1px solid #ddd !important;
      box-shadow: none;
      margin: 10px 5px 10px 5px !important;
      padding: 4px;
      text-align: center;
      font-family: 'InterFace', sans-serif;
      font-weight: bold;
      font-size: 21px !important;
      outline: none;
      color: #0054b1; }
  .product-quantity .add-to-basket-button {
    margin-top: 0;
    padding: 8px 30px;
    background-color: #0077be;
    border: 1px solid #0077be;
    border-radius: 4px;
    outline: none;
    font-family: 'InterFace', sans-serif;
    color: #fff;
    height: 48px;
    font-size: 14px; }
    .product-quantity .add-to-basket-button:hover, .product-quantity .add-to-basket-button:active {
      color: #0077be !important;
      background-color: #fff !important;
      border: 1px solid #0077be !important;
      border: none; }
  .product-quantity input[type=number]::-webkit-inner-spin-button,
  .product-quantity input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .product-quantity input[type=number] {
    -moz-appearance: textfield; }
  .product-quantity .weight-select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 4px 16px #ddd;
    font-size: 18px;
    border: 1px solid #d7dae2 !important;
    color: #9b9b9b;
    padding-left: 3%; }
