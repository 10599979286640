.join-now {
  background: url("../../../assets/bbsImages/Sweet chilli salmon .jpg"), linear-gradient(rgba(0, 0, 0, 0.27), rgba(0, 0, 0, 0.27));
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  margin-top: -70px; }
  .join-now .container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .join-now h2 {
    font-family: "InterFace", sans-serif;
    font-size: 3vw;
    color: #fff;
    line-height: 1; }
  .join-now .btn {
    margin-top: 40px; }
  .join-now .sinceYear {
    font-size: 18px;
    font-style: italic;
    color: white;
    margin-top: 35vh;
    margin-bottom: 30px; }
  @media screen and (max-width: 907px) {
    .join-now h2 {
      font-size: 5vw; } }

.about-us {
  font-family: "InterFace", sans-serif;
  padding: 114px 0 0; }
  .about-us .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .about-us .left {
    flex-grow: 1;
    width: 40%; }
    .about-us .left .content .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 25.47px; }
    .about-us .left .content p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px; }
      .about-us .left .content p:last-of-type {
        margin-bottom: 36px; }
    .about-us .left .content .btn {
      width: fit-content; }
  .about-us .right {
    position: relative;
    margin-left: 76px;
    max-width: 570px; }
    .about-us .right img {
      border-radius: 8px; }
    .about-us .right .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      margin-left: 80%;
      position: absolute;
      right: 0; }
    .about-us .right .small {
      text-transform: uppercase;
      font-size: 100%;
      margin-top: -50px; }
    .about-us .right .year {
      margin-top: -20px;
      font-size: 300%; }
  @media screen and (max-width: 991px) {
    .about-us .container {
      flex-direction: column; }
      .about-us .container .left {
        order: 2;
        width: 100%; }
        .about-us .container .left .btn {
          margin-bottom: 15px;
          width: 100%; }
        .about-us .container .left .title {
          font-size: 6vw; }
      .about-us .container .right {
        width: 100%;
        order: 1;
        margin-bottom: 83.14px;
        margin-left: 0; }
        .about-us .container .right img {
          width: 100%;
          height: auto; }
        .about-us .container .right .underlay {
          right: 0; } }

.quality {
  font-family: "InterFace", sans-serif;
  padding: 114px 0 103px; }
  .quality .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .quality .right {
    flex-grow: 1;
    width: 40%; }
    .quality .right .content .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 25.47px; }
    .quality .right .content p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px; }
    .quality .right .content .btn {
      width: fit-content; }
  .quality .left {
    position: relative;
    margin-right: 76px;
    max-width: 570px; }
    .quality .left img {
      border-radius: 8px; }
    .quality .left .small {
      text-transform: uppercase;
      font-size: 100%;
      margin-top: -50px; }
    .quality .left .year {
      margin-top: -20px;
      font-size: 300%; }
  .quality svg {
    font-size: 50px; }
  .quality .container-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px; }
    .quality .container-inline .right-inline {
      margin-left: 15px; }
  @media screen and (max-width: 991px) {
    .quality .container {
      flex-direction: column; }
      .quality .container .right {
        order: 2;
        width: 100%;
        margin-left: 0; }
        .quality .container .right .title {
          font-size: 6vw; }
      .quality .container .left {
        order: 1;
        margin-bottom: 83.14px;
        margin-left: 0;
        margin-right: 0; }
        .quality .container .left img {
          height: auto;
          width: 100%; }
        .quality .container .left .underlay {
          right: 0; } }
  @media screen and (min-width: 1300px) {
    .quality .container .right {
      margin-left: 76px; } }

.delivery {
  height: 400px;
  background: url("../../../assets/bbsImages/DeliveryVan.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "InterFace", sans-serif;
  padding: 114px 0 103px;
  color: #fff; }
  .delivery .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50% !important;
    text-align: center; }
    .delivery .container .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 25.47px; }
    .delivery .container p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px; }
  @media screen and (max-width: 991px) {
    .delivery .container {
      width: 100% !important; } }

.products {
  height: fit-content;
  background: url("../../../assets/bbsImages/ProductsBg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "InterFace", sans-serif;
  padding: 114px 0 103px; }
  .products .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .products .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25.47px; }
  .products #products-menu {
    width: 100%; }
    .products #products-menu ul {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #e0e1eb;
      margin-bottom: 50px;
      margin-top: 50px; }
      .products #products-menu ul .react-tabs__tab--selected {
        border: none;
        border-bottom: 3px solid #0054b1;
        color: #0054b1; }
      .products #products-menu ul li {
        min-width: 150px;
        text-align: center;
        color: #c19a5d;
        font-size: 16px;
        font-weight: bold; }
    .products #products-menu .react-tabs__tab-panel {
      display: flex;
      flex-wrap: wrap;
      gap: 10%; }
      .products #products-menu .react-tabs__tab-panel .product {
        flex: 45%;
        display: flex;
        justify-content: space-between;
        padding: 30px;
        color: #31302f;
        border-bottom: 1px dashed #e0e1eb; }
        .products #products-menu .react-tabs__tab-panel .product .product-title {
          font-size: 21px;
          font-weight: bold; }
        .products #products-menu .react-tabs__tab-panel .product .price-container .price {
          float: right;
          font-weight: bold;
          font-size: 21px; }
    @media screen and (min-width: 471px) {
      .products #products-menu .product {
        max-width: 45%; } }
    @media screen and (max-width: 471px) {
      .products #products-menu ul li {
        min-width: 33%; } }

.gallery {
  height: fit-content;
  background-color: #2e2d2c;
  font-family: "InterFace", sans-serif;
  padding: 114px 0 103px;
  color: #fff; }
  .gallery .container {
    display: flex;
    align-items: center;
    justify-content: center; }
    .gallery .container p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px; }
  .gallery .title {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25.47px; }
  .gallery .image-gallery {
    margin-top: 30px;
    overflow: hidden; }
    .gallery .image-gallery .image-gallery-icon:hover {
      background: transparent; }
    .gallery .image-gallery .image-gallery-thumbnail {
      width: 200px; }

.logged-out .contact-us {
  padding-top: 120px !important; }

.contact-us {
  height: fit-content;
  font-family: "InterFace", sans-serif;
  padding: 114px 0 103px; }
  .contact-us .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .contact-us .container.text {
      font-size: 16px; }
      @media screen and (max-width: 991px) {
        .contact-us .container.text {
          font-size: 14px; } }
    .contact-us .container .sales-email {
      cursor: pointer; }
      .contact-us .container .sales-email:hover {
        text-decoration: underline;
        color: #0077be; }
  .contact-us .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25.47px; }
  .contact-us #contactus {
    margin-bottom: 50px; }

.home-page {
  color: #262626; }
  .home-page .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 18px;
    font-family: "InterFace", sans-serif;
    color: #fff;
    padding: 5px 24px;
    background-color: #0077be;
    border: 1px solid #0077be;
    border-radius: 5px;
    line-height: 28px;
    letter-spacing: 0.02em;
    z-index: 1; }
    .home-page .btn.btn-outline {
      background-color: #fff;
      color: #262626; }
      .home-page .btn.btn-outline:hover {
        background-color: #0077be;
        color: #fff; }
    .home-page .btn:hover {
      background-color: #fff;
      color: #0077be; }
  .home-page .gold {
    color: #b39763; }
  .home-page .italic {
    font-style: italic;
    font-weight: 100 !important; }
    .home-page .italic.delivery-label {
      font-size: 18px; }
  .home-page > div {
    scroll-margin-top: 50px; }
  @media screen and (max-width: 991px) {
    .home-page .quality {
      padding: 0; }
    .home-page .delivery {
      padding: 50px;
      height: 300px; } }

.intro-info {
  width: 806px;
  font-size: 30px;
  font-weight: 500;
  font-family: "InterFace", sans-serif;
  margin-bottom: 94px;
  line-height: 36px;
  color: #262626; }
  @media screen and (max-width: 991px) {
    .intro-info {
      width: 100%;
      font-size: 24px;
      line-height: 28px; } }

.home-promo {
  position: relative;
  width: 100%;
  color: #262626;
  background: rgba(206, 180, 161, 0.15);
  margin-bottom: 60px; }
  .home-promo .eatfresh-promo-banner {
    position: relative;
    width: 50%;
    max-width: 688px;
    min-width: 688px;
    top: -20px;
    left: 0;
    margin-right: 20px; }
    .home-promo .eatfresh-promo-banner img {
      width: 100%;
      height: auto;
      max-height: 100%; }
    @media screen and (max-width: 1200px) {
      .home-promo .eatfresh-promo-banner {
        position: absolute;
        min-width: unset; } }
  .home-promo .container {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .home-promo .container .promo-content {
      width: 665px;
      width: 45%;
      padding: 95px 0 69px; }
      .home-promo .container .promo-content h2 {
        font-family: "InterFace", sans-serif;
        font-weight: 600 !important;
        font-size: 30px;
        line-height: 36px;
        margin: 0 0 27px; }
      .home-promo .container .promo-content p {
        font-family: "InterFace", sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px; }
        .home-promo .container .promo-content p:last-of-type {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 45px; }
      .home-promo .container .promo-content .btn {
        width: fit-content;
        height: 56px;
        padding: 18px 24px;
        border-radius: 3px; }
  @media screen and (max-width: 991px) {
    .home-promo {
      padding-bottom: 0; }
      .home-promo .container {
        display: block; }
        .home-promo .container .eatfresh-promo-banner {
          position: relative;
          width: 96%;
          top: -75px;
          margin: 0 auto;
          left: 0;
          height: auto; }
        .home-promo .container .promo-content {
          width: 100%;
          position: relative;
          top: -60px;
          padding: 46px 0 40px; } }
  @media screen and (max-width: 767px) {
    .home-promo .container .promo-content .btn {
      width: 100%; } }

.more-about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto 146px; }
  .more-about .item {
    width: 220px;
    text-align: center; }
    .more-about .item .icon {
      font-size: 34px;
      color: #0077be;
      margin-bottom: 15.3px; }
    .more-about .item p {
      font-family: "InterFace", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0 !important; }
  @media screen and (max-width: 991px) {
    .more-about {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .more-about {
      display: block;
      margin-bottom: 99px; }
      .more-about .item {
        max-width: 220px;
        margin: 0 auto 40px; } }

.choose-subscription {
  width: 100%;
  padding: 62.67px 0 107px; }
  .choose-subscription h3 {
    font-family: "InterFace", sans-serif;
    font-weight: 500 !important;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    margin: 0 0 35px; }
  .choose-subscription .subscriptions {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 42px; }
    .choose-subscription .subscriptions .subscription {
      max-width: 334px;
      flex-grow: 1;
      position: relative;
      font-family: "InterFace", sans-serif;
      font-weight: 600;
      padding: 50px 0 46px;
      margin-right: 20px;
      background-color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      text-align: center; }
      .choose-subscription .subscriptions .subscription .popular-banner {
        position: absolute;
        width: 82px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 26px;
        color: #262626;
        letter-spacing: 0.02em;
        background-color: #fcbc00;
        border-radius: 3px;
        top: 12px;
        right: -10px;
        text-transform: uppercase; }
      .choose-subscription .subscriptions .subscription:last-child {
        margin-right: 0; }
      .choose-subscription .subscriptions .subscription .title {
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 30px; }
      .choose-subscription .subscriptions .subscription .servings {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.02em;
        margin-bottom: 13px; }
      .choose-subscription .subscriptions .subscription .delivery {
        font-size: 14px;
        color: #0077be;
        line-height: 26px;
        letter-spacing: 0.02em;
        margin-bottom: 27px; }
      .choose-subscription .subscriptions .subscription .content {
        padding: 19px 20px 0;
        border-top: 2px solid #0077be; }
        .choose-subscription .subscriptions .subscription .content .delivery {
          margin-bottom: 16px;
          text-align: left; }
        .choose-subscription .subscriptions .subscription .content ul {
          display: flex;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0 0 20px;
          border: 1px solid #0077be;
          border-radius: 2px; }
          .choose-subscription .subscriptions .subscription .content ul li {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44.13px;
            text-align: center;
            color: #262626;
            border-left: 1px solid #0077be; }
            .choose-subscription .subscriptions .subscription .content ul li:first-child {
              border-left: none; }
        .choose-subscription .subscriptions .subscription .content .btn {
          width: 100%;
          font-size: 18px;
          line-height: 28px; }
  .choose-subscription .one-off {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 40px; }
  .choose-subscription .get-started {
    width: fit-content;
    margin-left: auto;
    margin-right: auto; }
  @media screen and (max-width: 767px) {
    .choose-subscription {
      padding-top: 20px; }
      .choose-subscription h3 {
        font-size: 32px; }
      .choose-subscription .subscriptions {
        display: block; }
        .choose-subscription .subscriptions .subscription {
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
          max-width: none; }
      .choose-subscription .get-started {
        width: 100%; } }
