.footer-logged-out {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  padding: 0 50px;
  position: absolute;
  margin-bottom: 70px; }
  .footer-logged-out .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 41%; }
    .footer-logged-out .left .orderly-info {
      color: #fff;
      font-size: 18px; }
      .footer-logged-out .left .orderly-info a {
        color: #fff;
        font-weight: 600; }
  .footer-logged-out .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 48%; }

@media screen and (max-width: 991px) {
  .footer-logged-out .left,
  .footer-logged-out .right {
    width: 100%; }
  .footer-logged-out .left {
    align-items: center; }
    .footer-logged-out .left img {
      max-width: 200px; }
    .footer-logged-out .left .text {
      margin: 20px 0 10px;
      text-align: center; }
      .footer-logged-out .left .text h2 {
        font-size: 38px; }
      .footer-logged-out .left .text h4 {
        max-width: none; }
    .footer-logged-out .left .orderly-info {
      display: none; }
  .footer-logged-out .right {
    justify-content: center;
    padding-top: 20px; } }
