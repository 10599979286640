.homepage {
  margin-top: 75px; }
  .homepage .banner .banner-content {
    top: 125px;
    margin-bottom: 110px; }
  .homepage .section-one .intro-text {
    font-family: "InterFace", sans-serif;
    font-size: 36px;
    line-height: 42px;
    max-width: 806px;
    margin: 80px 0; }
  .homepage .section-one .browse-recipes-promo .action-items {
    display: flex;
    justify-content: space-between;
    max-width: 926px;
    margin: 0 auto 35px; }
    .homepage .section-one .browse-recipes-promo .action-items .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .homepage .section-one .browse-recipes-promo .action-items .item .circle {
        height: 216px;
        width: 216px;
        background-color: #eee;
        border-radius: 50%;
        margin-bottom: 30px; }
      .homepage .section-one .browse-recipes-promo .action-items .item .text {
        font-family: "InterFace", sans-serif;
        font-size: 24px;
        line-height: 28px; }
    @media screen and (max-width: 767px) {
      .homepage .section-one .browse-recipes-promo .action-items {
        flex-wrap: wrap; }
        .homepage .section-one .browse-recipes-promo .action-items .item {
          width: 100%;
          margin: 20px 0; } }
  .homepage .section-one .browse-recipes-promo .browse-recipes {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 211px;
    margin: 0 auto; }
  .homepage .section-one .subscription-options {
    padding: 80px 0; }
    .homepage .section-one .subscription-options .recipe-slider {
      width: auto; }
  .homepage .strip-banner {
    background-color: #eee; }
    .homepage .strip-banner .action-items {
      display: flex;
      justify-content: space-between;
      max-width: 652px;
      margin: auto; }
      .homepage .strip-banner .action-items .item {
        display: flex;
        flex-direction: column;
        width: 198px;
        text-align: center;
        margin: 30px 10px; }
        .homepage .strip-banner .action-items .item .icon {
          font-size: 27px;
          margin-bottom: 15px; }
        .homepage .strip-banner .action-items .item .text {
          font-size: 18px;
          font-family: "InterFace", sans-serif;
          line-height: 24px; }
  .homepage .section-two .how-it-all-works {
    padding: 60px 0; }
    .homepage .section-two .how-it-all-works h2 {
      font-family: "InterFace", sans-serif;
      font-size: 48px;
      line-height: 54px;
      text-align: center;
      margin-bottom: 80px; }
    .homepage .section-two .how-it-all-works .content-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1138px;
      margin: auto; }
      .homepage .section-two .how-it-all-works .content-wrapper .step {
        width: 50%;
        display: flex; }
        .homepage .section-two .how-it-all-works .content-wrapper .step .image {
          position: relative;
          max-width: 224px; }
          .homepage .section-two .how-it-all-works .content-wrapper .step .image img {
            border-radius: 50%;
            width: 224px;
            height: 224px; }
        .homepage .section-two .how-it-all-works .content-wrapper .step .text {
          width: 400px;
          font-size: 20px;
          line-height: 28px; }
          .homepage .section-two .how-it-all-works .content-wrapper .step .text .number {
            display: block;
            font-family: "InterFace", sans-serif;
            font-size: 81px;
            line-height: 36px;
            color: rgba(0, 104, 58, 0.1);
            mix-blend-mode: normal;
            margin-bottom: 15px; }
          .homepage .section-two .how-it-all-works .content-wrapper .step .text h3 {
            font-size: 30px;
            font-family: "InterFace", sans-serif;
            line-height: 36px;
            mix-blend-mode: normal;
            margin: 0 0 20px; }
        .homepage .section-two .how-it-all-works .content-wrapper .step.step-1 .text {
          margin-top: 15px;
          padding-left: 30px; }
        .homepage .section-two .how-it-all-works .content-wrapper .step.step-1 .image::before {
          content: "";
          background: url("../../assets/EF_arrow1.svg");
          display: flex;
          height: 74px;
          width: 123px;
          background-size: cover;
          position: absolute;
          z-index: 1;
          left: 50%;
          top: -50px; }
        .homepage .section-two .how-it-all-works .content-wrapper .step.step-2 {
          flex-direction: column;
          align-items: flex-end;
          padding-right: 40px; }
          .homepage .section-two .how-it-all-works .content-wrapper .step.step-2 .text {
            margin-top: 15px; }
          .homepage .section-two .how-it-all-works .content-wrapper .step.step-2 .image::before {
            content: "";
            background: url("../../assets/EF_arrow3.svg");
            display: flex;
            height: 109px;
            width: 74px;
            background-size: cover;
            position: absolute;
            z-index: 1;
            right: -25px;
            top: 62%; }
        .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 {
          align-items: baseline; }
          .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 .text {
            margin-top: 15px; }
          .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 .image {
            order: 2; }
            .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 .image::before {
              content: "";
              background: url("../../assets/EF_arrow2.svg");
              display: flex;
              height: 64px;
              width: 117px;
              background-size: cover;
              position: absolute;
              z-index: 1;
              top: 45px;
              left: -30%; }
      @media screen and (max-width: 991px) {
        .homepage .section-two .how-it-all-works .content-wrapper .step {
          margin: 40px 0; }
          .homepage .section-two .how-it-all-works .content-wrapper .step.step-1, .homepage .section-two .how-it-all-works .content-wrapper .step.step-2, .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 {
            width: 100%;
            justify-content: center;
            align-items: center !important;
            flex-direction: row;
            padding-right: 0; }
            .homepage .section-two .how-it-all-works .content-wrapper .step.step-1 .text, .homepage .section-two .how-it-all-works .content-wrapper .step.step-2 .text, .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 .text {
              width: auto;
              order: 2;
              margin-top: 15px;
              padding-left: 30px; }
            .homepage .section-two .how-it-all-works .content-wrapper .step.step-1 .image::before, .homepage .section-two .how-it-all-works .content-wrapper .step.step-2 .image::before, .homepage .section-two .how-it-all-works .content-wrapper .step.step-3 .image::before {
              content: "";
              background: url("../../assets/EF_arrow1.svg");
              display: flex;
              height: 74px;
              width: 123px;
              background-size: cover;
              position: absolute;
              z-index: 1;
              left: 50%;
              top: -50px; } }
  .homepage .section-two .recipe-slider .container {
    width: auto; }
  .homepage .why-eatfresh {
    padding: 100px 0; }
    .homepage .why-eatfresh .content-wrapper {
      display: flex;
      justify-content: space-between; }
      .homepage .why-eatfresh .content-wrapper .image {
        width: 570px;
        min-height: 364px;
        background: url("../../assets/images/BulkOrdering.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 40px;
        border-radius: 3px; }
        .homepage .why-eatfresh .content-wrapper .image img {
          width: 100%; }
      .homepage .why-eatfresh .content-wrapper .text {
        width: 580px; }
        .homepage .why-eatfresh .content-wrapper .text h3 {
          font-family: "InterFace", sans-serif;
          font-size: 30px;
          line-height: 36px;
          margin-top: 0;
          margin-bottom: 7px; }
        .homepage .why-eatfresh .content-wrapper .text p {
          font-family: "InterFace", sans-serif;
          font-size: 18px;
          line-height: 24px; }
        .homepage .why-eatfresh .content-wrapper .text .get-started {
          margin-top: 30px;
          width: 148px;
          height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "InterFace", sans-serif;
          font-size: 18px;
          line-height: 28px;
          color: #fff; }
          .homepage .why-eatfresh .content-wrapper .text .get-started:hover {
            color: initial; }
      @media screen and (max-width: 767px) {
        .homepage .why-eatfresh .content-wrapper {
          flex-wrap: wrap;
          text-align: center; }
          .homepage .why-eatfresh .content-wrapper .image {
            width: 100%;
            margin: 0 auto 20px; }
          .homepage .why-eatfresh .content-wrapper .text {
            width: 100%; }
            .homepage .why-eatfresh .content-wrapper .text .get-started {
              margin: 20px auto 0; } }
  .homepage .section-three {
    background-color: #eee;
    padding: 60px 0; }
    .homepage .section-three .container .faq-wrapper {
      max-width: 688px;
      margin: auto; }
      .homepage .section-three .container .faq-wrapper h2 {
        margin-top: 0;
        margin-bottom: 23px;
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-family: "InterFace", sans-serif; }
      .homepage .section-three .container .faq-wrapper .contact-button {
        max-width: 148px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        padding: 0;
        margin: 0 auto; }
  .homepage .logged-in .container::before,
  .homepage .logged-in .container::after {
    content: none; }
