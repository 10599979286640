.contact-us {
  position: relative;
  padding: 0;
  margin-top: 75px; }
  .contact-us.widget {
    margin: 0; }
  .contact-us .contact-us-content {
    width: 100%;
    display: block;
    position: relative;
    margin: 0 auto; }
    .contact-us .contact-us-content .contact-us-header {
      width: 68%;
      background-color: #fff;
      margin-top: 33px;
      border-radius: 5px;
      padding-left: 20px;
      float: right; }
      .contact-us .contact-us-content .contact-us-header h1 {
        font-size: 28px;
        font-weight: bold !important;
        color: #0077be;
        text-align: left;
        margin-top: 0; }
      .contact-us .contact-us-content .contact-us-header h4 {
        color: #4a4a4a;
        font-weight: bold !important;
        font-size: 18px;
        text-align: left;
        padding-top: 27px;
        margin-bottom: 0;
        margin-top: 0; }
    .contact-us .contact-us-content .container {
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start; }
      .contact-us .contact-us-content .container .left {
        background-color: #f6f6f6; }
        .contact-us .contact-us-content .container .left .map-wrapper {
          background-image: url("../../assets/bbsImages/contact-us-map.jpg");
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 224px; }
        .contact-us .contact-us-content .container .left .map-details {
          padding: 20px; }
          .contact-us .contact-us-content .container .left .map-details h4 {
            font-size: 16px;
            color: #0077be;
            font-weight: bold !important;
            padding-bottom: 10px; }
          .contact-us .contact-us-content .container .left .map-details p {
            font-size: 18px;
            color: #4a4a4a;
            margin-bottom: 0; }
          .contact-us .contact-us-content .container .left .map-details #phone,
          .contact-us .contact-us-content .container .left .map-details #email {
            padding-bottom: 10px; }
          .contact-us .contact-us-content .container .left .map-details #email {
            font-style: italic; }
      .contact-us .contact-us-content .container .right {
        margin: 0 auto 0 auto;
        width: 65%; }
        .contact-us .contact-us-content .container .right p {
          color: #707070;
          font-size: 19px; }
        .contact-us .contact-us-content .container .right .submit-btn {
          margin-top: 10px;
          margin-bottom: 10px;
          float: right;
          font-weight: 700;
          font-size: 16px;
          padding: 10px 45px; }
          .contact-us .contact-us-content .container .right .submit-btn:hover {
            background-color: #fff;
            border: solid 1px #0077be;
            color: #0077be; }
  @media screen and (max-width: 1199px) {
    .contact-us .contact-us-content .contact-us-header {
      width: 80%;
      float: left; }
    .contact-us .contact-us-content .container {
      flex-wrap: wrap-reverse;
      justify-content: center; }
      .contact-us .contact-us-content .container .right {
        width: 100%; } }
