.user-summary {
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  height: fit-content; }
  .user-summary .panel-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../../assets/bbsImages/Sweet chilli salmon .jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .user-summary .panel-background::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 119, 190, 0.73); }
  .user-summary .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .user-summary .user-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 280px;
    height: 344px;
    padding: 25px;
    left: 0;
    font-family: "InterFace", sans-serif;
    background-color: #e9f2f8;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .user-summary .user-info .logo-wrapper {
      height: 104px;
      width: 104px; }
      .user-summary .user-info .logo-wrapper img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; }
      .user-summary .user-info .logo-wrapper svg {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 50%;
        color: #0054b1; }
    .user-summary .user-info h1 {
      font-size: 28px;
      font-weight: bold !important;
      text-align: center;
      color: #0054b1;
      line-height: 26px;
      margin: 0 0 12px; }
    .user-summary .user-info p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 19px; }
    .user-summary .user-info .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%; }
      .user-summary .user-info .button-wrapper a {
        font-size: 16px !important; }
      .user-summary .user-info .button-wrapper .btn {
        width: 100%; }
      .user-summary .user-info .button-wrapper .btn:not(.btn-outline) {
        font-weight: bold; }
      .user-summary .user-info .button-wrapper .btn-outline {
        color: #0054b1;
        border: 1px solid #0077be; }
  .user-summary .user-info > a > svg {
    color: #0054b1;
    align-self: flex-end;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer; }
  @media screen and (max-width: 991px) {
    .user-summary .user-info {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px; }
      .user-summary .user-info .button-wrapper {
        display: block; }
        .user-summary .user-info .button-wrapper .btn {
          width: 100%; }
          .user-summary .user-info .button-wrapper .btn:first-child {
            margin-right: 0;
            margin-bottom: 16px; }
      .user-summary .user-info h1 {
        font-size: 20px; } }
  .user-summary .order-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 75%;
    position: relative;
    padding: 25px;
    left: 0;
    font-family: "InterFace", sans-serif;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .user-summary .order-info .logo-wrapper {
      height: 104px;
      width: 104px; }
      .user-summary .order-info .logo-wrapper img {
        width: 100%;
        height: 100%;
        border-radius: 50%; }
    .user-summary .order-info h1 {
      font-size: 28px;
      font-weight: bold !important;
      text-align: left;
      color: #4a4a4a;
      line-height: 26px;
      margin: 0 0 4px 0; }
    .user-summary .order-info .order-total {
      font-size: 21px;
      font-weight: bold;
      color: #0054b1;
      margin: 0; }
    .user-summary .order-info .button-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between; }
      .user-summary .order-info .button-wrapper a {
        font-size: 16px !important; }
      .user-summary .order-info .button-wrapper .btn {
        width: 168px; }
      .user-summary .order-info .button-wrapper .btn:not(.btn-outline) {
        font-weight: bold; }
      .user-summary .order-info .button-wrapper .btn-outline {
        color: #0054b1;
        border: 1px solid #0077be; }
    .user-summary .order-info .orderTimeLeft {
      font-size: 18px;
      line-height: 20px;
      margin-top: auto;
      margin-bottom: 25px; }
    .user-summary .order-info #orderAvailability {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 20px; }
    .user-summary .order-info .horizontal-menu {
      margin-top: 25px; }
    .user-summary .order-info .menu-item {
      padding: 0 40px;
      margin: 3px;
      user-select: none;
      cursor: pointer;
      border: none;
      height: 80px;
      width: 80px;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .user-summary .order-info .scroll-menu-arrow {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      border-radius: 50% !important;
      background-color: #fff;
      margin-top: -5px; }
      .user-summary .order-info .scroll-menu-arrow .arrow-prev {
        transform: rotate(180deg); }
      .user-summary .order-info .scroll-menu-arrow svg {
        font-size: 26px;
        padding-top: 5px; }
      .user-summary .order-info .scroll-menu-arrow:nth-of-type(1) {
        left: 10px !important; }
      .user-summary .order-info .scroll-menu-arrow:last-of-type {
        right: 10px !important; }
    .user-summary .order-info .items-no {
      color: #0054b1; }
  @media screen and (max-width: 991px) {
    .user-summary .order-info {
      width: 100%;
      height: fit-content;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 25px; }
      .user-summary .order-info .button-wrapper {
        flex-wrap: wrap;
        margin-top: 15px; }
        .user-summary .order-info .button-wrapper .btn {
          width: 100%;
          margin-top: 20px; }
          .user-summary .order-info .button-wrapper .btn:first-child {
            margin-right: 0;
            margin-bottom: 16px; }
      .user-summary .order-info h1 {
        font-size: 20px; } }
  @media screen and (max-width: 470px) {
    .user-summary .order-info .button-wrapper {
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .user-summary .order-info {
      width: 65%; }
      .user-summary .order-info .order-total {
        padding-left: 10px; }
    .user-summary .horizontal-menu {
      margin-top: 8px !important; } }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    .user-summary .order-info {
      width: 75%; } }

.delivery-date-picker {
  display: flex;
  justify-content: center; }
  .delivery-date-picker .react-datepicker__input-container input {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 18px;
    font-family: "InterFace", sans-serif;
    color: #fff;
    padding: 5px 24px;
    background-color: #0077be;
    border: 1px solid #0077be;
    border-radius: 3px;
    line-height: 28px;
    letter-spacing: 0.02em;
    width: 168px; }
    .delivery-date-picker .react-datepicker__input-container input.react-datepicker-ignore-onclickoutside {
      border: 1px solid #0077be;
      background-color: #fff;
      color: #2a2e39; }
  .delivery-date-picker .react-datepicker {
    width: 334px;
    z-index: 0;
    box-shadow: 0 3.91438px 5.21918px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none !important;
    position: absolute;
    top: 35%; }
    .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__header {
      background-color: #fff !important;
      border-bottom: none; }
      .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
        font-family: "InterFace", sans-serif;
        color: #262626;
        font-size: 16px; }
    .delivery-date-picker .react-datepicker .react-datepicker__month-container .custom-date-picker-header {
      display: flex;
      padding-left: 5px; }
      .delivery-date-picker .react-datepicker .react-datepicker__month-container .custom-date-picker-header select {
        font-family: "InterFace", sans-serif;
        font-size: 18px;
        color: #262626;
        background-color: transparent;
        border: none; }
    .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month {
      font-family: "InterFace", sans-serif; }
      .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
        display: flex;
        justify-content: space-between; }
        .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day-name,
        .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
        .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__time-name {
          border-radius: 100%;
          height: 1.7rem;
          max-height: 1.7rem;
          max-width: 1.7rem;
          line-height: normal !important;
          -ms-flex-align: center;
          align-items: center;
          margin: 5px 0;
          font-size: 17px; }
        .delivery-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled {
          color: rgba(38, 38, 38, 0.4); }
    @media screen and (min-width: 300px) {
      .delivery-date-picker .react-datepicker {
        padding: 20px; }
        .delivery-date-picker .react-datepicker .react-datepicker__day,
        .delivery-date-picker .react-datepicker .react-datepicker__day-name {
          padding: 0 !important; } }

.copy-order-date-picker p {
  text-align: center;
  font-weight: 700; }

.copy-order-date-picker .react-datepicker__portal {
  position: unset !important;
  width: unset !important;
  height: unset !important;
  background-color: unset !important;
  left: unset !important;
  top: unset !important; }

.copy-order-date-picker .react-datepicker {
  width: 100% !important;
  z-index: 0;
  box-shadow: none !important;
  border-radius: 5px;
  border: none !important;
  position: relative !important;
  top: 0 !important; }
  .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__header {
    background-color: #fff !important;
    border-bottom: none; }
    .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
      font-family: "InterFace", sans-serif;
      color: #262626;
      font-size: 16px; }
  .copy-order-date-picker .react-datepicker .react-datepicker__month-container .custom-date-picker-header {
    display: flex;
    padding-left: 5px; }
    .copy-order-date-picker .react-datepicker .react-datepicker__month-container .custom-date-picker-header select {
      font-family: "InterFace", sans-serif;
      font-size: 18px;
      color: #262626;
      background-color: transparent;
      border: none; }
  .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month {
    font-family: "InterFace", sans-serif; }
    .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
      display: flex;
      justify-content: space-between; }
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day-name,
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__time-name {
        border-radius: 100%;
        height: 1.7rem;
        max-height: 1.7rem;
        max-width: 1.7rem;
        line-height: normal !important;
        -ms-flex-align: center;
        align-items: center;
        margin: 5px 0;
        font-size: 17px; }
      .copy-order-date-picker .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled {
        color: rgba(38, 38, 38, 0.4); }
  @media screen and (min-width: 300px) {
    .copy-order-date-picker .react-datepicker {
      padding: 20px; }
      .copy-order-date-picker .react-datepicker .react-datepicker__day,
      .copy-order-date-picker .react-datepicker .react-datepicker__day-name {
        padding: 0 !important; } }

.account-deliveries-page .delivery-item {
  border-bottom: 1px solid rgba(193, 154, 93, 0.5);
  border-left: 7px solid #fff;
  padding: 10px 0; }
  .account-deliveries-page .delivery-item:last-child {
    border-bottom: none; }
  .account-deliveries-page .delivery-item.title {
    font-weight: bold; }
  .account-deliveries-page .delivery-item.active {
    border-left: 7px solid #0077be; }
  .account-deliveries-page .delivery-item .row {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0; }
    .account-deliveries-page .delivery-item .row .column {
      text-align: center;
      flex: 1; }
    @media screen and (max-width: 480px) {
      .account-deliveries-page .delivery-item .row {
        font-size: 12px;
        padding-left: 15px;
        padding-right: 15px; } }
  .account-deliveries-page .delivery-item .date,
  .account-deliveries-page .delivery-item .meal,
  .account-deliveries-page .delivery-item .edit-text {
    display: flex;
    align-items: center;
    margin-bottom: 2px; }
    .account-deliveries-page .delivery-item .date .calendar-icon,
    .account-deliveries-page .delivery-item .date .info-icon,
    .account-deliveries-page .delivery-item .date .autorenew-icon,
    .account-deliveries-page .delivery-item .date .basket-icon,
    .account-deliveries-page .delivery-item .meal .calendar-icon,
    .account-deliveries-page .delivery-item .meal .info-icon,
    .account-deliveries-page .delivery-item .meal .autorenew-icon,
    .account-deliveries-page .delivery-item .meal .basket-icon,
    .account-deliveries-page .delivery-item .edit-text .calendar-icon,
    .account-deliveries-page .delivery-item .edit-text .info-icon,
    .account-deliveries-page .delivery-item .edit-text .autorenew-icon,
    .account-deliveries-page .delivery-item .edit-text .basket-icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
      color: #0077be; }
    .account-deliveries-page .delivery-item .date .info-icon,
    .account-deliveries-page .delivery-item .meal .info-icon,
    .account-deliveries-page .delivery-item .edit-text .info-icon {
      background-image: url("../account//deliveries/info-icon.svg"); }
  .account-deliveries-page .delivery-item .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px 10px 15px 10px; }
    .account-deliveries-page .delivery-item .button-wrapper .btn {
      border: 1px solid #0077be;
      color: #fff;
      background-color: #0077be;
      width: 30%;
      height: 44px;
      font-size: 14px; }
      .account-deliveries-page .delivery-item .button-wrapper .btn:hover {
        color: #0077be;
        background-color: #fff; }

.delivery-items-wrapper {
  margin-left: 15px; }
  .delivery-items-wrapper .item {
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 480px) {
      .delivery-items-wrapper .item {
        font-size: 12px;
        padding-left: 2px;
        padding-right: 2px; } }
    .delivery-items-wrapper .item.title {
      font-weight: bold;
      margin-bottom: 15px; }
    .delivery-items-wrapper .item .item-column {
      display: flex;
      flex-direction: column;
      flex: 1; }
      .delivery-items-wrapper .item .item-column.product-name {
        text-align: left;
        flex-grow: 1.5; }
      .delivery-items-wrapper .item .item-column.product-price {
        text-align: left; }
      .delivery-items-wrapper .item .item-column.product-quantity {
        text-align: center; }

.delivery-date-orders-dialog {
  min-width: 800px; }
  @media screen and (max-width: 991px) {
    .delivery-date-orders-dialog {
      min-width: 320px; } }
