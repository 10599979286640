.bbs-register {
  display: flex;
  background: url("../../assets/images/Bg-noblue.png") no-repeat 0 0;
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 100vh; }
  .bbs-register .register-container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 6% 6%;
    min-height: 1250px; }
    .bbs-register .register-container .inline-checkbox {
      display: flex; }
      .bbs-register .register-container .inline-checkbox .text-col,
      .bbs-register .register-container .inline-checkbox .checkbox-col {
        flex-flow: column; }
      .bbs-register .register-container .inline-checkbox .text-col {
        flex-flow: column;
        margin-left: 10px; }
    .bbs-register .register-container .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      margin-top: 70px; }
      .bbs-register .register-container .left .text h2 {
        font-family: "InterFace", sans-serif;
        font-size: 4.75vw;
        color: #fff;
        line-height: 1;
        margin: 0 0 20px; }
      .bbs-register .register-container .left .text h4 {
        font-family: "InterFace", sans-serif;
        color: #fff;
        font-size: 24px;
        max-width: 435px;
        margin: 0; }
      .bbs-register .register-container .left .orderly-info {
        color: #fff;
        font-size: 18px; }
        .bbs-register .register-container .left .orderly-info a {
          color: #fff;
          font-weight: 600; }
    .bbs-register .register-container .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 48%; }
      .bbs-register .register-container .right .register-panel {
        width: 100%;
        max-width: 620px;
        background-color: #fff;
        padding: 5% 5% 5%;
        align-self: center; }
        .bbs-register .register-container .right .register-panel .password-wrapper {
          position: relative; }
          .bbs-register .register-container .right .register-panel .password-wrapper .eye-icon {
            position: absolute;
            height: 100%;
            right: 0;
            padding: 10px;
            top: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 3; }
            .bbs-register .register-container .right .register-panel .password-wrapper .eye-icon .fa {
              color: inherit; }
            .bbs-register .register-container .right .register-panel .password-wrapper .eye-icon .visibility-icon,
            .bbs-register .register-container .right .register-panel .password-wrapper .eye-icon .no-visibility-icon {
              width: 15px;
              height: 15px; }
        .bbs-register .register-container .right .register-panel h3 {
          font-size: 45px;
          margin: 0 0 15px; }
        .bbs-register .register-container .right .register-panel p {
          margin: 0 0 20px; }
        .bbs-register .register-container .right .register-panel .forgot-password {
          display: flex;
          justify-content: space-between; }
        .bbs-register .register-container .right .register-panel .button-wrapper {
          margin: 20px 0; }
        .bbs-register .register-container .right .register-panel .not-registered {
          text-align: center; }
          .bbs-register .register-container .right .register-panel .not-registered p {
            margin: 0; }
        .bbs-register .register-container .right .register-panel .postcode-lookup .button-wrapper {
          margin: 0; }
          .bbs-register .register-container .right .register-panel .postcode-lookup .button-wrapper button {
            border: 1px solid #0077be;
            background-color: #0077be;
            color: #fff;
            border-radius: 4px;
            font-size: 14px; }
            .bbs-register .register-container .right .register-panel .postcode-lookup .button-wrapper button:hover {
              background-color: #fff;
              color: #0077be; }
      .bbs-register .register-container .right .orderly-logo {
        align-self: flex-end; }
  @media screen and (max-width: 991px) {
    .bbs-register .register-container .left,
    .bbs-register .register-container .right {
      width: 100%; }
    .bbs-register .register-container .left {
      align-items: center; }
      .bbs-register .register-container .left img {
        max-width: 200px; }
      .bbs-register .register-container .left .text {
        margin: 20px 0 10px;
        text-align: center; }
        .bbs-register .register-container .left .text h2 {
          font-size: 38px; }
        .bbs-register .register-container .left .text h4 {
          max-width: none; }
      .bbs-register .register-container .left .orderly-info {
        display: none; }
    .bbs-register .register-container .right {
      justify-content: center;
      padding-top: 20px; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .bbs-register .register-container .left .text h2 {
      font-size: 62px;
      line-height: 62px; }
    .bbs-register .register-container .right .register-panel {
      padding: 50px 40px 30px; } }
