.product-catalogue {
  min-height: 200px; }

.back-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: #fff;
  padding: 6px 20px;
  z-index: 1;
  box-shadow: 0 0 5px 1px #ccc;
  outline: none !important; }

.get-started {
  position: relative; }

.subscription-recipes {
  font-family: "InterFace", sans-serif;
  background-color: #f5f5f5; }

.subscription-recipes .recipes-wrapper {
  padding-top: 15px; }

.subscription-recipes .recipes-wrapper.catalogue {
  margin-top: 75px; }

.subscription-recipes .recipes-wrapper .container {
  display: flex; }

.subscription-recipes .recipes-wrapper .container .left {
  width: 25%;
  color: #4a4a4a;
  padding-right: 30px;
  font-family: "InterFace", sans-serif; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .container .left {
    transition: 0.5s;
    position: fixed;
    left: -260px;
    top: 58px;
    width: 240px;
    height: 100%;
    background-color: #fff;
    z-index: 3;
    box-shadow: -1px 0 5px 0 rgba(90, 90, 90, 0.15);
    padding: 20px !important;
    overflow: scroll; }
  .subscription-recipes .recipes-wrapper .container .left.show {
    left: 0; }
  .subscription-recipes .recipes-wrapper .container .left h1 {
    display: none; } }

.subscription-recipes .recipes-wrapper .container .left h1 {
  font-size: 24px;
  font-weight: bold !important;
  color: #2e2d2c;
  margin-top: 5px !important; }

.subscription-recipes .recipes-wrapper .container .left h4 {
  font-size: 20px;
  font-weight: bold !important;
  color: #2e2d2c; }

.subscription-recipes .recipes-wrapper .container .left .product-categories-filter {
  padding: 5px 0 40px 0; }
  .subscription-recipes .recipes-wrapper .container .left .product-categories-filter .clear-filter-button {
    font-size: 16px;
    text-decoration: underline;
    color: #0054b1;
    cursor: pointer; }

.subscription-recipes .recipes-wrapper .container .left .product-categories-filter a,
.subscription-recipes .recipes-wrapper .container .left .product-categories-filter .dropdown-btn {
  text-decoration: none;
  background: none;
  cursor: pointer;
  outline: none;
  color: #4a4a4a;
  font-size: 16px;
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
  display: flex;
  width: 100%; }

.subscription-recipes .recipes-wrapper .container .left .product-categories-filter a svg,
.subscription-recipes .recipes-wrapper .container .left .product-categories-filter .dropdown-btn svg {
  font-size: 21px;
  margin-left: auto; }

.subscription-recipes .recipes-wrapper .container .left .product-categories-filter a.active,
.subscription-recipes .recipes-wrapper .container .left .product-categories-filter .dropdown-btn.active {
  color: #0054b1; }

.subscription-recipes .recipes-wrapper .container .left .product-categories-filter a.active svg,
.subscription-recipes .recipes-wrapper .container .left .product-categories-filter .dropdown-btn.active svg {
  transform: rotate(90deg); }

.subscription-recipes .recipes-wrapper .container .left .product-categories-filter .dropdown-container {
  display: none;
  padding-left: 20px; }

.subscription-recipes .recipes-wrapper .container .left .filters h4 {
  margin-bottom: 30px; }

.subscription-recipes .recipes-wrapper .container .left .filters .checkbox-container {
  margin-left: 0;
  font-weight: normal;
  font-size: 14px; }

.subscription-recipes .recipes-wrapper .container .left .filters .checkbox-container span.checkbox {
  background-color: transparent;
  border: 1px solid #0077be;
  border-radius: 0 !important; }

.subscription-recipes .recipes-wrapper .container .right {
  width: 75%;
  font-family: "InterFace", sans-serif; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .container .right {
    width: 100%;
    margin-left: -20px; } }

.subscription-recipes .recipes-wrapper .container .right .right {
  width: auto;
  color: #2e2d2c; }

.subscription-recipes .recipes-wrapper .container .right .left {
  color: #2e2d2c; }

.subscription-recipes .recipes-wrapper .container .mobile-filter {
  display: none;
  z-index: 2; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .container .mobile-filter {
    display: block; } }

.subscription-recipes .recipes-wrapper .container .mobile-filter svg {
  font-size: 22px;
  margin-top: 5px; }

.subscription-recipes .recipes-wrapper .container .filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 -10px;
  background-color: rgba(51, 51, 51, 0.22);
  visibility: hidden; }

.subscription-recipes .recipes-wrapper .container .filter-overlay.show {
  visibility: visible;
  z-index: 2; }

.subscription-recipes .recipes-wrapper .filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px; }

.subscription-recipes .recipes-wrapper .filter-wrapper ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  overflow-x: auto; }

.subscription-recipes .recipes-wrapper .filter-wrapper ul li {
  color: #c19a5d;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  padding: 5px 15px;
  margin-right: 20px;
  text-transform: capitalize;
  cursor: pointer; }

.subscription-recipes .recipes-wrapper .filter-wrapper ul li:last-child {
  margin-right: 0; }

.subscription-recipes .recipes-wrapper .filter-wrapper ul li:hover,
.subscription-recipes .recipes-wrapper .filter-wrapper ul li.active {
  color: #0054b1; }

.subscription-recipes .recipes-wrapper .filter-wrapper ul li.active {
  border-bottom: 2px solid #0054b1; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .filter-wrapper {
    margin-left: 30px; } }

.subscription-recipes .recipes-wrapper .listing-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .listing-wrapper {
    justify-content: flex-end; } }

.subscription-recipes .recipes-wrapper .listing-wrapper .left {
  white-space: nowrap;
  font-size: 20px;
  font-weight: bold;
  line-height: 36px; }

.subscription-recipes .recipes-wrapper .listing-wrapper .right {
  font-size: 18px;
  line-height: 28px; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .listing-wrapper .right {
    margin-left: 0; } }

.subscription-recipes .recipes-wrapper .recipes {
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  justify-content: space-between;
  padding: 15px 0; }

.subscription-recipes .recipes-wrapper .recipes .recipe {
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-direction: column; }

@media screen and (max-width: 1199px) {
  .subscription-recipes .recipes-wrapper .recipes {
    grid-template-columns: repeat(auto-fill, 48%); }
  .subscription-recipes .recipes-wrapper .recipes .recipe {
    height: auto; } }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper .recipes {
    grid-template-columns: repeat(auto-fill, 100%); }
  .subscription-recipes .recipes-wrapper .recipes .recipe {
    height: auto; } }

.subscription-recipes .recipes-wrapper .load-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; }

.subscription-recipes .recipes-wrapper .load-more .btn {
  width: 146px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 20px;
  background-color: #0077be;
  color: #fff;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px;
  margin-top: 10px; }

.subscription-recipes .recipes-wrapper .load-more .btn:hover {
  background-color: #fff;
  transition: none;
  color: #2a2e39; }

.subscription-recipes .recipes-wrapper .load-more span {
  display: block;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0.02em;
  font-family: "InterFace", sans-serif; }

@media screen and (max-width: 767px) {
  .subscription-recipes .recipes-wrapper {
    padding: 20px 0; } }

.ratings-wrapper {
  width: 100%;
  min-height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  padding: 40px; }

.mobile-view-basket-button {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 24px;
  background-color: #fff;
  z-index: 1;
  text-align: center;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.15); }

.mobile-view-basket-button p {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 14px; }

.mobile-view-basket-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 57px;
  font-family: "InterFace", sans-serif;
  font-size: 18px;
  color: #fff;
  background-color: #0077be; }

.mobile-view-basket-button button:hover {
  border: 1px solid #0077be;
  background-color: #fff;
  color: #2a2e39 !important; }

@media screen and (max-width: 991px) {
  .subscription-recipes {
    padding-top: 109px; }
  .mobile-view-basket-button {
    display: block; } }
