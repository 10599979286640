@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?cy01g4");
  src: url("../fonts/icomoon.eot?cy01g4#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?cy01g4") format("truetype"), url("../fonts/icomoon.woff?cy01g4") format("woff"), url("../fonts/icomoon.svg?cy01g4#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-info2::before {
  content: "\e93b"; }

.icon-box-open::before {
  content: "\e900"; }

.icon-calendar::before {
  content: "\e901"; }

.icon-cancel::before {
  content: "\e902"; }

.icon-cart::before {
  content: "\e903"; }

.icon-star-filled::before {
  content: "\e904"; }

.icon-shipping::before {
  content: "\e905"; }

.icon-star-outline::before {
  content: "\e906"; }

.icon-pause-circle-outline::before {
  content: "\e907"; }

.icon-profile::before {
  content: "\e908"; }

.icon-repeat::before {
  content: "\e909"; }

.icon-radio-checked::before {
  content: "\e90a"; }

.icon-radio-unchecked::before {
  content: "\e90b"; }

.icon-checked::before {
  content: "\e90c"; }

.icon-account::before {
  content: "\e90d"; }

.icon-afternoon::before {
  content: "\e90e"; }

.icon-arrow-bottom-white::before {
  content: "\e90f"; }

.icon-arrow-left::before {
  content: "\e910"; }

.icon-clock::before {
  content: "\e911"; }

.icon-mail::before {
  content: "\e912"; }

.icon-info-circle::before {
  content: "\e913"; }

.icon-location::before {
  content: "\e914"; }

.icon-oval::before {
  content: "\e915"; }

.icon-padlock::before {
  content: "\e916"; }

.icon-plus::before {
  content: "\e917"; }

.icon-times::before {
  content: "\e918"; }

.icon-arrow-right::before {
  content: "\e919"; }

.icon-vegetarian::before {
  content: "\e91a"; }

.icon-arrow-top::before {
  content: "\e91b"; }

.icon-basket::before {
  content: "\e91c"; }

.icon-calendar1::before {
  content: "\e91d"; }

.icon-calories::before {
  content: "\e91e"; }

.icon-canceled::before {
  content: "\e91f"; }

.icon-card::before {
  content: "\e920"; }

.icon-icon-clock::before {
  content: "\e921"; }

.icon-coin::before {
  content: "\e922"; }

.icon-collected::before {
  content: "\e923"; }

.icon-cupcake::before {
  content: "\e924"; }

.icon-error::before {
  content: "\e925"; }

.icon-evening::before {
  content: "\e926"; }

.icon-eye::before {
  content: "\e927"; }

.icon-facebook::before {
  content: "\e928"; }

.icon-geolocation::before {
  content: "\e929"; }

.icon-home::before {
  content: "\e92a"; }

.icon-info::before {
  content: "\e92b"; }

.icon-inprogress::before {
  content: "\e92c"; }

.icon-instagram::before {
  content: "\e92d"; }

.icon-marker::before {
  content: "\e92e"; }

.icon-menu::before {
  content: "\e92f"; }

.icon-morning::before {
  content: "\e930"; }

.icon-orderconfirmation::before {
  content: "\e931"; }

.icon-phone::before {
  content: "\e932"; }

.icon-search::before {
  content: "\e933"; }

.icon-store::before {
  content: "\e934"; }

.icon-twitter::before {
  content: "\e935"; }

.icon-youtube::before {
  content: "\e936"; }

.icon-spinner::before {
  content: "\e937"; }

.icon-delivery::before {
  content: "\e938"; }

.icon-calendar2::before {
  content: "\e939"; }

.icon-carrot::before {
  content: "\e93a"; }
